/*
 * @Author: zhijiezhang
 * @Date: 2022-03-04 18:16:04
 * @Last Modified by: yuhaoyang
 * @Last Modified time: 2023-02-07 14:56:00
 */

import Chat from 'common/resource';

export default {
    state: {
        setting: null
    },

    getters: {
        setting: state => state.setting
    },

    mutations: {
        saveSetting(state, setting) {
            state.setting = setting;
        }
    },

    actions: {
        async fetchSetting({commit}, params) {
            try {
                const {data: {body, body: {id}}} = await Chat.getChannelSetting({params});

                const setting = JSON.parse(body?.setting);
                delete setting.fastBtnList;
                commit('saveSetting', {id, ...setting});
            } catch (err) {
                // err
            }
        },

        async fetchFastBtns({commit, state}) {
            try {
                if (!state?.setting?.id) return;
                const {data: {body: fastBtnList}} = await Chat.getFashButton({params: {id: state?.setting?.id}});
                commit('saveSetting', {
                    ...state?.setting,
                    fastBtnList
                });
            } catch (err) {
                // err
            }
        }
    }
};
