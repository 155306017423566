<template>
    <a-config-provider :locale="AntLocaleMap[i18n.locale]">
        <a-spin
            :spinning="reqCount > 0"
            class="yqg-spin"
        >
            <ToolBox v-if="!$options.isProd && $options.isYchat" />
            <router-view />
            <template
                v-if="fileUploadMap"
                #indicator
            >
                <a-progress
                    class="progress"
                    stroke-linecap="square"
                    :percent="progress"
                    type="circle"
                />
            </template>
        </a-spin>
    </a-config-provider>
</template>

<script type="text/babel">
import Cookie from 'js-cookie';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

import {axios as http, fixAxiosGetUri} from '@yqg/resource';

import {apiErrorReportToSentry} from '@shared/client/util/sentry-error';

import {AntLocaleMap} from '@cs/common/constant/language-map';
import checkVersion from '@cs/common/mixin/check-version';

import Component from 'common/component';
import ToolBox from 'common/component/tool-box';
import {isProd, isYchat} from 'common/constant/host-cond';
import {
    YQG_STATUS_CODE_BATCH_CONTENT_ERROR,
    YQG_STATUS_NEED_CAPTCHA
} from 'common/constant/yqg-status';
import directive from 'common/directive';
import i18n from 'common/i18n';
import UploadEvent from 'common/mixin/upload-event';
import router, {setTitle} from 'common/router';
import store from 'common/store';
import {pre} from 'common/util';
import {openSensor, initSensor} from 'common/util/sensors-upload';
import {setBodyFontSize} from 'common/util/style';

window.Deposit = param => {
    store.commit('saveParam', param);
};

const TOAST_STATUS_WHITE_LIST = [
    YQG_STATUS_NEED_CAPTCHA,
    YQG_STATUS_CODE_BATCH_CONTENT_ERROR
];

const YqgFormat = {
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
    dateTime: 'YYYY-MM-DD HH:mm:ss'
};
moment.YqgFormat = YqgFormat;
fixAxiosGetUri();

Vue.use(Component);
Vue.use(directive);

export default {
    name: 'App',

    router,

    store,

    i18n,

    isProd,

    isYchat,

    components: {ToolBox},

    mixins: [UploadEvent, checkVersion],

    data() {
        return {
            reqCount: 0,
            AntLocaleMap,
            i18n,
            fileUploadMap: null
        };
    },

    computed: {
        ...mapGetters(['getHostCond']),

        progress() {
            const {fileUploadMap} = this;
            if (!fileUploadMap) return 0;
            let total = 0, cur = 0;
            Object.keys(fileUploadMap).forEach(key => {
                const {loaded, total: totalItem} = fileUploadMap[key];
                total += (totalItem || 0);
                cur += (loaded || 0);
            });

            return Math.ceil((cur / (total + 1)) * 90);
        }
    },

    watch: {
        $route: {
            handler(val) {
                // 根据meta.useRem的值来判断是否使用rem单位
                const {meta: {useRem, fontSize, remFontSize} = {}} = val;
                setBodyFontSize({useRem: useRem || false, fontSize, remFontSize});
            }
        },
    },

    async created() {
        Vue.prototype.$app = this;
        this.initHttpInterceptor();
        this.initGlobalSensor();
        this.checkVersion();

        try {
            await this.fetchHostCond();
        } catch (err) {
            YqgReporter.fatalException('HostCond initial fetch failed!', err);
        }

        router.beforeEach((to, from, next) => {
            setTitle(to);
            next();
        });

        document.addEventListener('WeixinJSBridgeReady', () => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge?.call('hideOptionMenu');
        });

        const {route} = router.resolve(location.pathname);
        setTitle(route);

        if (this.getHostCond('openDebug')) {
            import('vconsole').then(({default: VConsole}) => {
                Vue.use(new VConsole());
            });
        }
    },

    methods: {
        ...mapActions(['fetchHostCond']),

        initHttpInterceptor() {
            // http request 拦截器
            http.interceptors.request.use(
                config => {
                    config.headers['YQG-PLATFORM-LANGUAGE'] = this.i18n?.locale;
                    if (config.url.startsWith('/v2/')) {
                        config.headers['build'] = '28000';
                        config.headers['YQG-PLATFORM-LANGUAGE'] = 'zh-cn';
                        config.headers['YQG-PLATFORM-SDK-TYPE'] = 'ZEBRA';
                    }

                    if (!config.hideLoading) {
                        this.reqCount += 1;
                    }

                    if (config.uploadProgress) {
                        if (!this.fileUploadMap) {
                            this.fileUploadMap = {};
                        }

                        const data = {};
                        config.data?.forEach((value, key) => data[key] = value);
                        this.$set(this.fileUploadMap, data.file.uid, {
                            total: data.file.size,
                        });

                        config.onUploadProgress = res => {
                            this.$set(this.fileUploadMap, data.file.uid, res);
                        };
                    }

                    return config;
                },
                err => {
                    this.reqCount = Math.max(0, this.reqCount - 1);
                    if (!this.reqCount) {
                        this.fileUploadMap = null;
                    }

                    return Promise.reject(err);
                }
            );

            // http response 拦截器
            http.interceptors.response.use(
                res => {
                    const {status, data: {status: {code, detail} = {}}, config, config: {hideLoading, responseType, uploadProgress}} = res;
                    if (!hideLoading) {
                        this.reqCount = Math.max(0, this.reqCount - 1);
                    }

                    if (uploadProgress && !this.reqCount) {
                        this.fileUploadMap = null;
                    }

                    if (responseType === 'blob') {
                        return res;
                    }

                    switch (code) {
                        case 0: // api 正常返回
                            return Promise.resolve(res);

                        default: {
                            apiErrorReportToSentry(config, {
                                httpCode: status,
                                code,
                                detail
                            });

                            if (!hideLoading && !TOAST_STATUS_WHITE_LIST.includes(code)) {
                                const content = detail || this.$t(pre('unknownError'));
                                this.$message.error(content);
                            }
                        }
                    }

                    return Promise.reject(res);
                },
                error => {
                    const {response: {config: {hideLoading, uploadProgress} = {}} = {}, isAxiosError} = error;
                    if (!hideLoading) {
                        this.reqCount = Math.max(0, this.reqCount - 1);
                    }

                    if (uploadProgress && !this.reqCount) {
                        this.fileUploadMap = null;
                    }

                    if (!isAxiosError) {
                        this.$message.error(error.message || this.$t(pre('internetError')));
                    }

                    return Promise.reject(error.response || error);
                }
            );
        },

        initGlobalSensor() {
            try {
                if (openSensor) {
                    let userId;
                    const {$route: {query: {userToken, usertoken}}} = this;

                    const token = userToken || usertoken || Cookie.get('userToken');

                    if (usertoken) {
                        userId = token.split('-')?.[1];
                    }

                    initSensor(userId);
                }
            } catch {
                // ignore
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.progress {
    ::v-deep &.ant-spin-dot {
        margin-left: -60px !important;
    }
}
</style>
