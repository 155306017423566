import Cookie from 'js-cookie';

export const setCookie = (key, value) => {
    Cookie.set(key, value);
};

export const clearCookies = () => {
    Object.keys(Cookie.get()).forEach(item => {
        Cookie.remove(item);
    });
};

export const getCookie = key => {
    return Cookie.get(key);
};
