/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 15:54:50 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-09-07 16:54:44
 */

/* eslint-disable max-len */

export default {
    name: 'checkbox-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M732.093 341.985c-12.544-12.452-32.829-12.318-45.245 0.256L453.565 578.048l-103.26-106.112c-12.354-12.703-32.609-12.928-45.25-0.64-12.672 12.319-12.959 32.61-0.64 45.25l126.018 129.506c0.062 0.097 0.195 0.097 0.256 0.19 0.067 0.066 0.098 0.189 0.159 0.255 2.017 1.987 4.51 3.2 6.881 4.547 1.25 0.67 2.243 1.792 3.523 2.304a31.689 31.689 0 0 0 12.098 2.401c4.06 0 8.126-0.799 11.966-2.335 1.25-0.517 2.207-1.54 3.394-2.18 2.402-1.342 4.895-2.525 6.943-4.542 0.067-0.067 0.097-0.195 0.195-0.261 0.066-0.098 0.158-0.123 0.256-0.19l256.225-259.005c12.442-12.55 12.314-32.804-0.236-45.25zM832 928H192c-52.925 0-96-43.07-96-96V192c0-52.925 43.075-96 96-96h640c52.93 0 96 43.075 96 96v640c0 52.93-43.07 96-96 96zM192 160c-17.633 0-32 14.367-32 32v640c0 17.664 14.367 32 32 32h640c17.664 0 32-14.336 32-32V192c0-17.633-14.336-32-32-32H192z'
                }
            }
        ]
    }
};
