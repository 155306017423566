/*
 * @Author: zhijiezhang 
 * @Date: 2021-05-29 14:36:23 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-11-29 18:57:10
 */

import {
    Button,
    ConfigProvider,
    Dropdown,
    Divider,
    Drawer,
    Form,
    Icon,
    Input,
    Tooltip,
    message,
    Modal,
    Rate,
    Result,
    Spin,
    Upload,
    Progress,
    Select,
    Empty,
    Collapse
} from 'ant-design-vue';
import {
    Tab,
    Tabs,
    Cell,
    Radio,
    Popup,
    Field,
    Picker,
    Checkbox,
    Cascader,
    CellGroup,
    RadioGroup,
    DropdownMenu,
    ImagePreview,
    DropdownItem,
    CheckboxGroup,
    DatetimePicker,
    Form as VanForm,
    Button as VanButton,
    Uploader as VanUpload,
    Rate as VanRate,
    Step,
    Steps,
    Swipe,
    SwipeItem,
    Tag,
    Toast,
    Empty as VanEmpty,
    Collapse as VanCollapse,
    CollapseItem
} from 'vant';
import VueClipboard from 'vue-clipboard2';

import Filters from '../filter';

function globalUse(plugins) {
    Object.keys(plugins).forEach(key => {
        this[`$${key}`] = plugins[key];
    });
}

export default {
    install(Vue) {
        [
            Button,
            ConfigProvider,
            Dropdown,
            Divider,
            Drawer,
            Form,
            Icon,
            Input,
            Modal,
            Tooltip,
            Spin,
            Rate,
            Result,
            Upload,
            Progress,
            Cascader,
            DatetimePicker,
            Field,
            Cell,
            CellGroup,
            DropdownMenu,
            DropdownItem,
            Select,
            VanForm,
            RadioGroup,
            Radio,
            Popup,
            Picker,
            Checkbox,
            VanButton,
            VanUpload,
            VanRate,
            CheckboxGroup,
            Empty,
            Collapse,
            ImagePreview,
            Step,
            Steps,
            Swipe,
            SwipeItem,
            Tab,
            Tabs,
            Tag,
            Toast,
            VanEmpty,
            VanCollapse,
            CollapseItem
        ].forEach(plugin => Vue.use(plugin));

        Vue.use(Filters);

        const {info} = Modal;
        globalUse.call(Vue.prototype, {
            info,
            message
        });

        Vue.use(VueClipboard);
    }
};
