/*
 * @Author: xiaodongyu
 * @Date 2020-02-06 18:51:31
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2024-04-24 16:49:43
 */

import qs from 'qs';
import Vue from 'vue';
import Router from 'vue-router';

import {$t} from 'common/i18n';
import {pre} from 'common/util';

import routes from './routes';

Vue.use(Router);

const scrollBehavior = (to, from, savePosition) => {
    if (savePosition) {
        return savePosition;
    }

    const position = {y: 0};

    if (to.hash) {
        position.selector = to.hash;
        position.offset = {y: 80};
    }

    return position;
};

const parseQuery = query => qs.parse(query);

const stringifyQuery = query => {
    const result = qs.stringify(query);

    return result ? `?${result}` : '';
};

const router = new Router({
    mode: 'history',
    fallback: false,
    scrollBehavior,
    routes,
    parseQuery,
    stringifyQuery
});

export {routes};
const rootTitle = pre('online');

export const setTitle = ({meta: {navTitle: title, call, subTitleInvisible}}) => {
    if (subTitleInvisible) {
        document.title = title;

        return;
    }

    const subTitle = call ? '电话客服' : $t(rootTitle);
    document.title = `${title ? `${$t(title)} - ` : ''}${subTitle}`;
};

export default router;
