/*
 * @Author: wenxiujiang 
 * @Date: 2022-06-28 13:07:04 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-08-08 14:38:51
 */

import moment from 'moment';

const dateTime = timestamp => timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : '';
const date = timestamp => timestamp ? moment(timestamp).format('YYYY.MM.DD') : '';
const numberWithCommas = x => {
    if (!x && x !== 0 && x !== '0') return '';

    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};

export default {
    install(Vue) {
        Vue.filter('dateTime', dateTime);
        Vue.filter('date', date);
        Vue.filter('numberWithCommas', numberWithCommas);
    }
};
