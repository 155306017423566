/*
 * @Author: wenxiujiang 
 * @Date: 2022-12-07 14:47:05 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-12 23:39:28
 */

/* eslint-disable max-len */

export default {
    name: 'eating',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M 1013.37 415.946 c 0 -157.545 -127.642 -285.187 -285.187 -285.187 c -86.993 0 -164.795 38.9655 -217.093 100.327 c -52.2993 -61.3612 -130.101 -100.327 -217.093 -100.327 c -157.545 0 -285.187 127.642 -285.187 285.187 c 0 82.721 37.93 151.849 91.3943 209.198 l 412.052 395.999 L 917.191 629.543 c 51.3932 -53.4644 96.1841 -128.547 96.1841 -213.599 Z',
                    fill: '#fee8eb'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M 389.019 119.755 c -23.949 -33.3991 -75.8599 -71.0702 -105.246 -91.0061 c -9.06177 -6.08432 -21.2304 0.388362 -21.2304 11.2625 v 879.251 c 46.3445 0 83.7566 -37.5415 83.7566 -83.7566 L 344.746 477.047 c 41.8135 -34.6937 110.294 -265.381 44.2732 -357.293 Z M 843.532 26.418 c -25.373 0 -46.0855 19.6769 -46.0855 43.7554 v 131.913 h -55.2768 V 48.297 c 0 -12.0391 -10.3563 -21.8778 -23.0429 -21.8778 s -23.0429 9.83849 -23.0429 21.8778 v 153.791 h -55.2768 V 70.175 c 0 -24.0784 -20.7126 -43.7554 -46.0855 -43.7554 v 263.051 c 0 45.5678 36.7649 83.2388 83.4977 87.7696 v 502.928 c 0 21.4893 18.5119 39.095 41.1663 39.095 S 760.551 901.655 760.551 880.166 V 377.108 c 46.4739 -4.78979 82.8505 -42.3314 82.8505 -87.7696 v -87.3813 l 0.129454 -175.54 Z',
                    fill: '#ff8882'
                }
            }
        ]
    }
};
