import wx from 'weixin-js-sdk';

// 小程序常用接口
export default {
    navigateTo(url) {
        if (url === '/pages/home/index') {
            wx.miniProgram.switchTab({url});

            return;
        }

        // h5链接
        if (url?.startsWith('http://') || url?.startsWith('https://')) {
            wx.miniProgram.navigateTo({url: `/pages/common/web-view/index?redirectUrl=${encodeURIComponent(url)}`});

            return;
        }

        // 小程序链接
        wx.miniProgram.navigateTo({url});
    },

    redirectTo(url) {
        if (url === '/pages/home/index') {
            wx.miniProgram.switchTab({url});

            return;
        }

        // h5链接
        if (url?.startsWith('http://') || url?.startsWith('https://')) {
            wx.miniProgram.redirectTo({url: `/pages/common/web-view/index?redirectUrl=${encodeURIComponent(url)}`});

            return;
        }

        // 小程序链接
        wx.miniProgram.redirectTo({url});
    },

    navigateBack() {
        wx.miniProgram.navigateBack();
    },

    /**
     * 网页向小程序 postMessage 时，会在以下特定时机触发并收到消息：小程序后退、组件销毁、分享、复制链接
     * @param {*} data 
     */
    postMessage(data) {
        wx.miniProgram.postMessage({data});
    },

    switchTab(url) {
        wx.miniProgram.switchTab({url});
    }
};
