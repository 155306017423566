/*
 * @Author: chengyuzhang 
 * @Date: 2020-12-17 14:33:44 
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-12-21 16:58:17
 */

/* eslint-disable max-len */

export default {
    name: 'custom-arrow-up',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 117.22752c4.77184 0 9.2672 2.21184 12.17536 6.00064l289.4848 376.5248c2.12992 2.75456 3.18464 6.06208 3.18464 9.35936 0 3.25632-1.03424 6.5024-3.09248 9.24672-4.15744 5.51936-11.48928 7.5776-17.90976 5.03808L604.16 447.68256l0 443.72992c0 8.48896-6.87104 15.36-15.36 15.36l-153.6 0c-8.48896 0-15.36-6.87104-15.36-15.36L419.84 447.68256l-191.67232 75.71456c-6.42048 2.53952-13.75232 0.47104-17.90976-5.03808-4.15744-5.50912-4.13696-13.12768 0.09216-18.60608l289.47456-376.5248C502.7328 119.4496 507.22816 117.22752 512 117.22752z'
                }
            }
        ]
    }
};
