/*
 * @Author: suzilin
 * @Date: 2022-08-04 18:06:19
 * @Last Modified by: suzilin  
 * @Last Modified time: 2022-08-04 18:06:21  
 */

import generateEvent from './event';

const event = generateEvent();

export default {
    install(Vue) {
        Vue.directive('yqg-event', event);
    }
};
