/*
 * @Author: wenxiujiang
 * @Date: 2022-06-15 14:52:46
 * @Last Modified by: ruiwang
 * @Last Modified time: 2022-10-10 19:56:24
 */

import {getPre} from '@yqg/vue/antd/constant/fields';

export const pre = getPre('');

export function dataURLtoFile(dataUrl, fileName) {
    const arr = dataUrl.split(';base64,'); //[data:image/png,xxx]
    const mime = arr[0].replace('data:', ''); //mime后端识别用的文件格式数据
    const fileType = mime.split('/').pop(); //获取文件格式
    const bstr = atob(arr[1]); //base64解码
    //创建Unicode编码的数组对象，每个值都是Unicode
    const u8arr = new Uint8Array(bstr.split('').map(str => str.charCodeAt(0)));

    return new File([u8arr], `${fileName ?? 'file'}.${fileType}`, {type: mime});
}

