/*
 * @Author: yuhaoyang
 * @Date: 2023-02-09 11:22:16
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-12-07 18:46:50
 */

import EVENTS from '@cs/common/constant/events/client';

import EventUtil from '../../common/util/event';
import {isOverseas} from '../constant/global';

export default {
    methods: {
        uploadEvent(evt) {
            const {$route} = this;
            let {target} = evt;
            let value = target.dataset.upload;
            const maxDep = 4;
            let curDep = 0;
            // 绑定的data-upload并不在点击的元素上，需要到最近的父节点寻找
            while (!value && curDep < maxDep && target?.parentNode) {
                target = target.parentNode;
                value = target?.dataset?.upload;
                curDep ++;
            }

            if (value) {
                value = JSON.parse(value);
            } else {
                return;
            }

            // 海外app支持匿名登录-无customerId
            if (!value?.customerId && (value.ovc && !isOverseas)) return;
            const {key} = value;

            if (value.customerId) {
                value.userId = value.customerId;
            }

            const {tag, businessEvent} = EVENTS[key] || {};

            EventUtil.uploadEvent(
                {
                    ...value,
                    tag,
                    businessEvent
                },
                $route
            );
        }
    },

    mounted() {
        const {uploadEvent} = this;
        document.body.addEventListener('click', uploadEvent);
    },

    beforeDestroy() {
        const {uploadEvent} = this;
        document.body.removeEventListener('click', uploadEvent);
    }
};
