/*
 * @Author: xiaoxueli
 * @Date: 2022-01-11 10:51:19
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-01-13 10:04:32
 */

/* eslint-disable max-len */

export default {
    name: 'coupon-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M883.21 63.07c77.76 0 140.8 63.04 140.8 140.8v179.2c0 21.21-17.19 38.4-38.4 38.4-49.48 0-89.6 40.11-89.6 89.6 0 49.48 40.12 89.59 89.6 89.59 21.21 0 38.4 17.2 38.4 38.4v179.2c0 77.76-63.04 140.8-140.8 140.8h-742.4c-77.76 0-140.8-63.04-140.8-140.8v-179.2c0-21.21 17.19-38.4 38.4-38.4 49.48 0 89.59-40.11 89.59-89.59 0-49.49-40.11-89.6-89.59-89.6-21.21 0-38.4-17.19-38.4-38.4v-179.2c0-77.76 63.04-140.8 140.8-140.8h742.4z m0 76.8h-742.4c-16.97 0-33.25 6.74-45.26 18.74a64.02 64.02 0 0 0-18.74 45.26v145.25c73.38 17.33 128 83.26 128 161.95 0 78.68-54.62 144.61-128 161.94v145.25a64.02 64.02 0 0 0 18.74 45.26 64.02 64.02 0 0 0 45.26 18.74h742.4c16.97 0 33.25-6.74 45.25-18.74a63.96 63.96 0 0 0 18.74-45.26V673.02c-73.38-17.33-128-83.26-128-161.94 0-78.69 54.62-144.62 128-161.95V203.87a64.02 64.02 0 0 0-18.74-45.26 64.02 64.02 0 0 0-45.25-18.74z m-523.77 149.7c16.29-13.58 40.5-11.37 54.08 4.92l98.5 118.21 98.5-118.21c13.58-16.29 37.8-18.49 54.09-4.92 16.29 13.58 18.5 37.8 4.92 54.09l-86.21 103.41h56.71c20.33 0 37.14 15.85 38.33 36.15l0.06 2.26c0 21.21-17.19 38.4-38.4 38.4h-83.2v51.2h83.2c20.33 0 37.14 15.85 38.33 36.15l0.06 2.26c0 21.21-17.19 38.4-38.4 38.4h-83.2v51.2c0 20.33-15.85 37.14-36.15 38.33l-2.25 0.06c-21.21 0-38.4-17.19-38.4-38.4v-51.2h-96c-20.33 0-37.14-15.85-38.34-36.15l-0.06-2.25c0-21.21 17.2-38.4 38.4-38.4h96v-51.2h-96c-20.33 0-37.14-15.85-38.34-36.15l-0.06-2.25c0-21.21 17.2-38.4 38.4-38.4h56.68l-86.18-103.42c-12.97-15.57-11.62-38.54 3.09-52.46l1.84-1.63z m0 0'
                }
            }
        ]
    }
};
