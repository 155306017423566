/*
 * @Author: xingpan
 * @Date: 2024-03-14 10:28:27
 * @Last Modified by: suzilin
 * @Last Modified time: 2024-04-01 16:08:49
 */
import * as Sentry from '@sentry/vue';
export const apiErrorReportToSentry = function (request, response) {
    try {
        const { url, method, params, data } = request || {};
        const { httpCode, code, traceId, detail } = response || {};
        const isFileUploadApi = data instanceof FormData;
        Sentry.withScope(scope => {
            scope.setLevel('info');
            scope.setTags({
                httpCode,
                isFileUploadApi,
                requestUrl: url,
                requestMethod: method,
                responseCode: code,
                responseTraceId: traceId,
                responseMessage: detail
            });
            scope.setContext('requestParams', { params, data });
            Sentry.captureException(new Error(url));
        });
    }
    catch (error) {
        // ignore
    }
};
