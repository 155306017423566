/*
 * @Author: xiaoxueli
 * @Date: 2021-04-25 16:57:20
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-06-09 10:02:12
 */

/* eslint-disable max-len */

export default {
    name: 'pull-unblack',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M777.344 556.032a230.4 230.4 0 1 0 0 460.8 230.4 230.4 0 0 0 0-460.8z m-171.776 230.4a171.776 171.776 0 0 1 171.776-171.776 170.24 170.24 0 0 1 104.32 36.096l-249.152 227.2a170.432 170.432 0 0 1-26.88-91.52z m171.776 171.776a170.24 170.24 0 0 1-104.32-36.16l249.216-227.2c16.832 26.624 26.88 57.856 26.88 91.584a171.776 171.776 0 0 1-171.776 171.776z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M587.136 595.2c2.752 0.768 5.504 1.664 8.576 1.664a30.528 30.528 0 1 0 15.04-57.408l0.384-0.832c-1.088-0.384-2.176-0.64-3.2-1.088l-0.576-0.128a431.488 431.488 0 0 0-22.528-7.616c85.76-48.832 144.256-140.032 144.256-245.824a283.968 283.968 0 0 0-567.872 0c0 109.12 62.208 202.752 152.512 250.304a430.976 430.976 0 0 0-288 391.744H26.24c-0.064 0.832-0.448 1.472-0.448 2.304 0 17.024 13.824 30.912 30.912 30.912 21.312 0 37.312-13.824 37.312-30.912 0-0.832-0.384-1.472-0.448-2.304h0.64c7.872-196.928 163.2-347.904 362.048-347.904 46.08 0 90.048 2.048 130.688 17.472l0.192-0.448zM445.184 504.768a220.8 220.8 0 1 1 0-441.728 220.8 220.8 0 0 1 0 441.728z'
                }
            }
        ]
    }
};
