/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-31 18:20:23 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-31 18:24:12
 */

/* eslint-disable max-len */

export default {
    name: 'circle-plus',
    theme: 'fill',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M828.704 196.576C744.096 112.384 631.648 66.016 512 66.016s-232.129 46.368-316.737 130.56C110.624 280.8 64 392.832 64 512c0 119.199 46.624 231.2 131.232 315.424 84.608 84.192 197.089 130.56 316.737 130.56 119.648 0 232.129-46.368 316.704-130.56 84.672-84.255 131.296-196.288 131.263-315.455 0.031-119.168-46.56-231.169-131.232-315.393z m-92.703 347.425h-192v192c0 17.696-14.336 32.001-32.001 32.001s-32.001-14.304-32.001-32.001v-192H288.001C270.336 544.001 256 529.665 256 512s14.336-32.001 32.001-32.001h192V288.001c0-17.665 14.336-32.001 32.001-32.001 17.664 0 32.001 14.336 32.001 32.001v192h192c17.695 0 32 14.336 32 32.001 0 17.664-14.306 31.999-32.002 31.999z'
                }
            }
        ]
    }
};
