/*
 * @Author: wenxiujiang 
 * @Date: 2021-06-09 09:30:42 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-06-09 09:32:49
 */

/* eslint-disable max-len */

export default {
    name: 'locking',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M216.1664 438.852267V292.5568C216.1664 131.003733 348.603733 0 512 0c163.362133 0 295.8336 131.003733 295.8336 292.5568v146.295467h1.604267A146.295467 146.295467 0 0 1 955.733333 585.147733v292.5568A146.295467 146.295467 0 0 1 809.437867 1024H214.562133A146.295467 146.295467 0 0 1 68.266667 877.704533v-292.5568a146.295467 146.295467 0 0 1 146.295466-146.295466h1.604267z m73.966933 0h443.733334V292.5568c0-121.173333-99.328-219.409067-221.866667-219.409067S290.133333 171.349333 290.133333 292.522667v146.295466z m221.047467 219.4432a73.147733 73.147733 0 1 0 0 146.261333h1.6384a73.147733 73.147733 0 0 0 0-146.261333h-1.6384z'
                }
            }
        ]
    }
};
