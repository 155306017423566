/*
 * @Author: suzilin 
 * @Date: 2024-03-25 16:53:20 
 * @Last Modified by:   suzilin 
 * @Last Modified time: 2024-03-25 16:53:20 
 */

/* eslint-disable max-len */

export default {
    name: 'mobile',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M756.363636 907.636364h-465.454545a58.228364 58.228364 0 0 1-58.181818-58.181819v-98.443636h581.818182v98.443636c0 32.093091-26.088727 58.181818-58.181819 58.181819m-465.454545-791.272728h465.454545C788.456727 116.363636 814.545455 142.452364 814.545455 174.545455v506.647272H232.727273V174.545455C232.727273 142.452364 258.816 116.363636 290.909091 116.363636m465.454545-69.818181h-465.454545A128.162909 128.162909 0 0 0 162.909091 174.545455v674.90909c0 70.562909 57.437091 128 128 128h465.454545c70.562909 0 128-57.437091 128-128v-674.90909C884.363636 103.982545 826.926545 46.545455 756.363636 46.545455m-232.727272 733.090909a46.545455 46.545455 0 1 0 0.023272 93.114181A46.545455 46.545455 0 0 0 523.636364 779.636364'
                }
            }
        ]
    }
};
