/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 16:48:30 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:04:33
 */

/* eslint-disable max-len */

export default {
    name: 'money-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 102.4c225.28 0 409.6 184.32 409.6 409.6s-184.32 409.6-409.6 409.6-409.6-184.32-409.6-409.6 184.32-409.6 409.6-409.6m0-51.2c-253.44 0-460.8 207.36-460.8 460.8s207.36 460.8 460.8 460.8 460.8-207.36 460.8-460.8-207.36-460.8-460.8-460.8z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M691.2 593.92h-153.6v-102.4h153.6c12.8 0 25.6-12.8 25.6-25.6 0-15.36-12.8-25.6-25.6-25.6h-117.76l128-128c10.24-10.24 10.24-25.6 0-35.84-10.24-10.24-25.6-10.24-35.84 0l-153.6 153.6-153.6-153.6c-10.24-10.24-25.6-10.24-35.84 0-10.24 10.24-10.24 25.6 0 35.84l128 128h-117.76c-12.8 0-25.6 10.24-25.6 25.6 0 12.8 12.8 25.6 25.6 25.6h153.6v102.4h-153.6c-12.8 0-25.6 12.8-25.6 25.6s12.8 25.6 25.6 25.6h153.6v128c0 12.8 12.8 25.6 25.6 25.6s25.6-12.8 25.6-25.6v-128h153.6c12.8 0 25.6-12.8 25.6-25.6s-12.8-25.6-25.6-25.6z'
                }
            }
        ]
    }
};
