/*
 * @Author: wenxiujiang 
 * @Date: 2024-02-01 16:12:10 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2024-02-01 16:15:12
 */

export default {
    name: 'cus-warning',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 0C229.254842 0 0.010628 229.244214 0.010628 511.989372c0 282.766414 229.244214 512.010628 511.989372 512.010628 282.766414 0 511.989372-229.244214 511.989372-512.010628C1024.010628 229.244214 794.78767 0 512 0zM580.146217 804.23589l-136.271178 0L443.875039 687.626362l136.271178 0L580.146217 804.23589zM580.146217 591.443695l-136.271178 0L443.875039 219.76411l136.271178 0L580.146217 591.443695z',
                    fill: '#d81e06'
                }
            }
        ]
    }
};
