const useRem = true;
const subTitleInvisible = true;
const component = {template: '<router-view/>'};

export default [
    {
        // 海外邮件满意度评价
        path: '/overseas-email-satisfaction',
        name: 'overseasEmailSatisfaction',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        // 海外邮件满意度评价短链接
        path: '/es/:ticketId/:ticketNumber/:sign',
        name: 'overseasEmailSatisfactionShort',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        // 海外邮件满意度评价短链接 优化2期
        path: '/es/:ticketId/:sign',
        name: 'overseasEmailSatisfactionShortV2',
        component: () => import('app/overseas/email-satisfaction')
    },
    {
        path: '/overseas-help-center',
        name: 'overseasHelpCenter',
        component: () => import('app/overseas/help-center'),
        meta: {
            useRem
        }
    },
    {
        // indo - wa - 满意度评价
        path: '/indo-wa-satisfaction',
        name: 'indoWaSatisfaction',
        component: () => import('app/overseas/wa-satisfaction')
    },
    {
        path: '/overseas-complaints-step',
        name: 'overseasComplaintsStep',
        component: () => import('app/overseas/help-center/complaints-step'),
        meta: {
            useRem
        }
    },
    {
        path: '/overseas/self-service',
        name: 'overseasSelfService',
        component,
        redirect: {name: 'serviceHandling'},
        children: [
            {
                name: 'applyExemption',
                path: 'apply-exemption',
                component: () => import('app/overseas/self-service/exemption/apply'),
                meta: {
                    navTitle: 'Mengajukan Permohonan',
                    useRem,
                    subTitleInvisible
                }
            },
            {
                name: 'applyExemptionResult',
                path: 'apply-exemption-result',
                component: () => import('app/overseas/self-service/exemption/result'),
                meta: {
                    navTitle: 'Hasil Pengajuan',
                    useRem,
                    subTitleInvisible
                }
            },
            {
                name: 'exemptionRecordList',
                path: 'exemption-record-list',
                component: () => import('app/overseas/self-service/exemption/record/list'),
                meta: {
                    navTitle: 'Progress Layanan',
                    useRem,
                    subTitleInvisible
                }
            },
            {
                name: 'exemptionRecordDetail',
                path: 'exemption-record-detail',
                component: () => import('app/overseas/self-service/exemption/record/detail'),
                meta: {
                    navTitle: 'Rincian Layanan',
                    useRem,
                    subTitleInvisible
                }
            }
        ]
    }
];
