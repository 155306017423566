/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 15:34:31 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-31 10:13:26
 */

/* eslint-disable max-len */

export default {
    name: 'text-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '24 24 960 960', width: 60},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M849.066667 140.8V341.333333h-21.333334c-12.8-46.933333-25.6-76.8-38.4-98.133333-12.8-21.333333-34.133333-34.133333-59.733333-46.933333-12.8-8.533333-38.4-8.533333-72.533333-8.533334h-55.466667v567.466667c0 38.4 0 59.733333 4.266667 72.533333 4.266667 8.533333 12.8 17.066667 25.6 25.6 12.8 8.533333 29.866667 8.533333 51.2 8.533334h25.6v21.333333h-384V853.333333h25.6c21.333333 0 38.4-4.266667 51.2-12.8 8.533333-4.266667 17.066667-12.8 21.333333-25.6 4.266667-8.533333 4.266667-29.866667 4.266667-68.266666V179.2H375.466667c-51.2 0-85.333333 8.533333-106.666667 29.866667-29.866667 29.866667-51.2 72.533333-59.733333 123.733333h-21.333334V132.266667h661.333334z'
                }
            }
        ]
    }
};
