import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';

import {reportBuilder} from '@yqg/reporter';

import {initSentry} from '@shared/client/util/sentry';

import router from 'src/common/router';

import '@shared/client/vue-error-handler';

import App from './app';

import 'common/icons';
import 'common/style/index.scss';

reportBuilder.setOptions({
    defaultAjaxUrl: '/api-web/report'
});

initSentry({
    router,
    tracesSampleRate: 1,
    dsn: 'https://574ebd2735b8ca739a49730da40d3310@sentry.fintopia.tech/63'
});

new Vue({
    render: createElement => createElement(App)
}).$mount('#app');
