/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 16:00:53 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-09-07 17:01:17
 */

/* eslint-disable max-len */

export default {
    name: 'select-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M877.714 0H146.286C65.829 0 0 65.829 0 146.286v731.428C0 958.171 65.829 1024 146.286 1024h731.428C958.171 1024 1024 958.171 1024 877.714V146.286C1024 65.829 958.171 0 877.714 0z m73.143 877.714c0 36.572-29.257 73.143-73.143 73.143H146.286c-36.572 0-73.143-29.257-73.143-73.143V146.286c0-36.572 29.257-73.143 73.143-73.143h731.428c36.572 0 73.143 29.257 73.143 73.143v731.428z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M746.057 380.343L512 636.343l-234.057-256c-14.629-14.629-36.572-14.629-51.2 0-7.314 14.628-7.314 36.571 0 51.2l256 285.257c14.628 7.314 21.943 14.629 29.257 14.629s14.629-7.315 21.943-14.629l256-285.257c14.628-14.629 14.628-36.572 0-51.2-7.314-21.943-29.257-21.943-43.886 0z'
                }
            }
        ]
    }
};
