/**
 * @author center
 * @date 2018/3/28-下午6:20
 * @file ios-webview-javascript-bridge
 */

/* eslint-disable */

export default function () {
    if (window.WebViewJavascriptBridge) {return;}
    let messagingIframe;
    let sendMessageQueue = [];
    let receiveMessageQueue = [];
    const messageHandlers = {};

    const CUSTOM_PROTOCOL_SCHEME = 'wvjbscheme';
    const QUEUE_HAS_MESSAGE = '__WVJB_QUEUE_MESSAGE__';

    const responseCallbacks = {};
    let uniqueId = 1;

    function _createQueueReadyIframe(doc) {
        messagingIframe = doc.createElement('iframe');
        messagingIframe.style.display = 'none';
        messagingIframe.src = `${CUSTOM_PROTOCOL_SCHEME}://${QUEUE_HAS_MESSAGE}`;
        doc.documentElement.appendChild(messagingIframe);
    }

    function init(messageHandler) {
        if (WebViewJavascriptBridge._messageHandler) {throw new Error('WebViewJavascriptBridge.init called twice');}
        WebViewJavascriptBridge._messageHandler = messageHandler;
        const receivedMessages = receiveMessageQueue;
        receiveMessageQueue = null;
        for (let i = 0; i < receivedMessages.length; i++) {
            _dispatchMessageFromObjC(receivedMessages[i]);
        }
    }

    function send(data, responseCallback) {
        _doSend({data}, responseCallback);
    }

    function registerHandler(handlerName, handler) {
        messageHandlers[handlerName] = handler;
    }

    function callHandler(handlerName, data, responseCallback) {
        _doSend({handlerName, data}, responseCallback);
    }

    function _doSend(message, responseCallback) {
        if (responseCallback) {
            const callbackId = `cb_${uniqueId++}_${new Date().getTime()}`;
            responseCallbacks[callbackId] = responseCallback;
            message.callbackId = callbackId;
        }
        sendMessageQueue.push(message);
        messagingIframe.src = `${CUSTOM_PROTOCOL_SCHEME}://${QUEUE_HAS_MESSAGE}`;
    }

    function _fetchQueue() {
        const messageQueueString = JSON.stringify(sendMessageQueue);
        sendMessageQueue = [];
        return messageQueueString;
    }

    function _dispatchMessageFromObjC(messageJSON) {
        setTimeout(() => {
            const message = JSON.parse(messageJSON);
            let messageHandler;
            let responseCallback;

            if (message.responseId) {
                responseCallback = responseCallbacks[message.responseId];
                if (!responseCallback) {return;}
                responseCallback(message.responseData);
                delete responseCallbacks[message.responseId];
            } else {
                if (message.callbackId) {
                    const callbackResponseId = message.callbackId;
                    responseCallback = function (responseData) {
                        _doSend({responseId: callbackResponseId, responseData});
                    };
                }

                let handler = WebViewJavascriptBridge._messageHandler;
                if (message.handlerName) {
                    handler = messageHandlers[message.handlerName];
                }

                try {
                    handler(message.data, responseCallback);
                } catch (exception) {
                    if (typeof console != 'undefined') {
                        console.log('WebViewJavascriptBridge: WARNING: javascript handler threw.', message, exception);
                    }
                }
            }
        });
    }

    function _handleMessageFromObjC(messageJSON) {
        if (receiveMessageQueue) {
            receiveMessageQueue.push(messageJSON);
        } else {
            _dispatchMessageFromObjC(messageJSON);
        }
    }

    window.WebViewJavascriptBridge = {
        init,
        send,
        registerHandler,
        callHandler,
        _fetchQueue,
        _handleMessageFromObjC
    };

    const doc = document;
    _createQueueReadyIframe(doc);
    const readyEvent = doc.createEvent('Events');
    readyEvent.initEvent('WebViewJavascriptBridgeReady');
    readyEvent.bridge = WebViewJavascriptBridge;
    doc.dispatchEvent(readyEvent);
}
