/*
 * @Author: zhijiezhang 
 * @Date: 2021-12-07 11:14:00 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2021-12-07 11:23:30
 */

export default {
    state: {
        param: null
    },

    getters: {
        param: state => state.param
    },

    mutations: {
        saveParam(state, param) {
            state.param = param;
        }
    }
};

