<template>
    <div class="tool">
        <a-button
            icon="tool"
            @click="openTools"
        />

        <a-modal
            title="工具箱"
            :visible="visible"
            :mask-closable="true"
            :footer="null"
            @cancel="drawerClose"
        >
            <div
                v-if="$options.isOnlyYchat"
                class="loginLine"
            >
                <p>获取token: </p>
                <a-input
                    v-model="phone"
                    style="margin: 0 10px;"
                />
                <a-button
                    type="primary"
                    @click="getToken"
                >
                    登录
                </a-button>
            </div>
            <hr>
            当前渠道:  {{ curChannel }}
            <div style="margin-top: 10px;">
                渠道选择
                <a-button
                    v-for="(i, idx) in channels"
                    :key="idx"
                    style="margin-right: 5px;margin-bottom: 5px;"
                    :type="i.code === $route.query.channelCode ? 'primary' : 'default'"
                    @click="selectChannel(i)"
                >
                    {{ i.name }}
                </a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import {isOnlyYchat} from 'common/constant/host-cond';
import ToolResource from 'common/resource/tool';
import {clearCookies} from 'common/util/cookie';

export default {
    data() {
        return {
            visible: false,
            channels: null,
            phone: null
        };
    },

    isOnlyYchat,

    computed: {
        curChannel() {
            const {channelCode} = this.$route.query;
            if (!channelCode) return '未选择';

            return this.channels?.find(i => i.code === channelCode)?.name || '当前链接channelCode无效';
        }
    },

    methods: {
        openTools() {
            this.visible = true;
            this.fetchChannels();
        },

        drawerClose() {
            this.visible = false;
        },

        async fetchChannels() {
            const {data: {body}} = await ToolResource.getChannels({params: {
                status: 'ABLE',
                type: 'APP'
            }});
            this.channels = body;
        },

        selectChannel(i) {
            this.$router.replace({
                name: this.$route.name,
                query: {
                    ...this.$route.query,
                    channelCode: i.code,
                    clientType: i.type.toLowerCase(),
                    appCode: i.code.indexOf('wealth') > -1 ? 'wealth' : 'cash_loan'
                }
            });
            location.reload();
        },

        async getToken() {
            const {phone} = this;
            if (!phone) {
                this.$message.error('输入手机号');

                return;
            }

            clearCookies();

            const params = {
                channelName: 'ZEBRA_WEB_NATURE',
                deviceToken: `WAP-2.0-${Date.now()}`,
                mobileNumber: phone,
                verificationCheckType: 'MESSAGE',
                verificationCode: '141212',
                verificationPurpose: 'REGISTER_OR_LOGIN'
            };
            await ToolResource.getDeviceToken();
            const {data: {body}} = await ToolResource.generateCaptcha();
            const nextParams = {
                captcha: '1412',
                captchaKey: body,
                mobileNumber: phone,
                verificationPurpose: 'REGISTER_OR_LOGIN'
            };
            await ToolResource.sendVerification(nextParams);
            await ToolResource.getUserV2Token(params);
            this.$message.success(`${phone}:登陆成功`);

            window.setTimeout(() => {
                window.location.reload();
            }, 1000);

            // window.open('https://appwebtest.yangqianguan.com/v2/cash-loan/repayment/zebra/login');
        }
    }
};
</script>

<style scoped>
.tool {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

.loginLine {
    display: flex;
}
</style>
