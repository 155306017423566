/*
 * @Author: chengyuzhang 
 * @Date: 2020-12-17 14:39:30 
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-12-21 16:55:07
 */

/* eslint-disable max-len */

export default {
    name: 'custom-arrow-down',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M813.63968 524.24704l-289.46432 376.5248c-2.89792 3.77856-7.424 6.00064-12.17536 6.00064-4.78208 0-9.27744-2.22208-12.17536-6.00064l-289.4848-376.5248c-2.11968-2.7648-3.18464-6.06208-3.18464-9.3696 0-3.24608 1.024-6.5024 3.10272-9.24672 4.13696-5.50912 11.47904-7.56736 17.89952-5.03808L419.84 576.3072l-0.02048-443.71968c0.02048-8.48896 6.88128-15.36 15.38048-15.36l153.57952 0c8.4992 0 15.36 6.87104 15.38048 15.36L604.13952 576.3072l191.68256-75.7248c6.42048-2.52928 13.76256-0.47104 17.92 5.04832C817.89952 511.15008 817.87904 518.76864 813.63968 524.24704z'
                }
            }
        ]
    }
};
