/*
 * @Author: yuhaoyang
 * @Date: 2023-06-19 17:45:08
 * @Last Modified by: haoyuyang@yangqianguan.com
 * @Last Modified time: 2023-06-19 17:45:57
 */

export default {
    state: {
        customerId: null
    },

    getters: {
        customerId: state => state.customerId
    },

    mutations: {
        saveCustomerId(state, customerId) {
            state.customerId = customerId;
        }
    }
};
