/*
 * @Author: xiaoxueli
 * @Date: 2021-04-25 16:57:20
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-06-09 10:02:22
 */

/* eslint-disable max-len */

export default {
    name: 'pull-black',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M665.821007 506.327482a351.622446 351.622446 0 0 0-87.371511-54.220432 228.374101 228.374101 0 1 0-239.719136 0C192.276259 514.504748 86.561151 687.774388 86.561151 891.395683a29.467626 29.467626 0 0 0 29.467626 29.467626h368.345324a276.111655 276.111655 0 0 1 181.446906-414.535827z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M873.788777 623.461295a217.39741 217.39741 0 0 0-303.884892-3.315108l-0.957698 0.81036-0.442014 0.515683-2.062734 1.989065a217.323741 217.323741 0 1 0 307.347338 0z m-153.673669-6.703885a159.419856 159.419856 0 0 1 113.376691 47.000863 160.67223 160.67223 0 0 1 20.40633 201.926907L631.71223 643.351942a159.419856 159.419856 0 0 1 88.402878-26.594532zM606.738417 890.658993a160.230216 160.230216 0 0 1-16.133525-207.746763l223.73295 223.880288a160.009209 160.009209 0 0 1-207.599425-16.133525z'
                }
            }
        ]
    }
};
