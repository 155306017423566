/*
 * @Author: xiaodongyu
 * @Date 2021-08-18 12:44:18
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2024-01-23 17:18:27
 */

import {axios as http} from '@yqg/resource';

export default {
    state: {
        hostCond: null,
        getHostCond: () => ({})
    },

    getters: {
        hostCond: state => state.hostCond,
        getHostCond: state => key => state.hostCond?.[key]
    },

    mutations: {
        saveHostCond(state, hostCond) {
            state.hostCond = hostCond;
        }
    },

    actions: {
        async fetchHostCond({commit}) {
            const {data: {body}} = await http.get('/api-web/get-host-cond', {hideLoading: true});
            commit('saveHostCond', body);
        }
    }
};
