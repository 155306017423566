/*
 * @Author: yuhaoyang
 * @Date: 2021-07-02 13:54:10
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-08-03 19:44:10
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/ocs-admin/satisfaction',

    getTemplate: {
        url: 'templateForUser/:businessCode',
        method: 'get',
    },

    evaluation: {
        url: 'evaluation',
        method: 'post'
    },

    robotEvaluation: {
        url: 'robot-evaluation',
        method: 'post'
    },

    robotEvaluationCheck: {
        url: 'robot-evaluation/check',
        method: 'post'
    },

    getSatisfactionStatus: {
        url: '/ocs-admin/conversation/:id',
        method: 'get'
    },

    getEvaluationDetail: {
        url: 'evaluation/client',
        method: 'get'
    }
};

export default Resource.create(api);
