/*
 * @Author: yuhaoyang
 * @Date: 2021-11-18 15:18:44
 * @Last Modified by: yuhaoyang 
 * @Last Modified time: 2021-11-19 15:56:11 
 */

/* eslint-disable max-len */

export default {
    name: 'dynamic-select-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M898 55H126c-19.3 0-35 15.7-35 35v844c0 19.3 15.7 35 35 35h772c19.3 0 35-15.7 35-35V90c0-19.3-15.6-35-35-35z m-35 844H161V125h702v774z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M608.2 335.2h166.2c19.3 0 35-15.7 35-35s-15.7-35-35-35H608.2c-19.3 0-35 15.7-35 35s15.7 35 35 35zM443.2 547.1c0 19.3 15.7 35 35 35h296.2c19.3 0 35-15.7 35-35s-15.7-35-35-35H478.2c-19.3 0-35 15.7-35 35zM774.4 758.9H298.2c-19.3 0-35 15.7-35 35s15.7 35 35 35h476.2c19.3 0 35-15.7 35-35s-15.7-35-35-35zM245.2 445.8c6.8 6.8 15.8 10.3 24.7 10.3 9 0 17.9-3.4 24.7-10.3l56.1-56.1 56.1 56.1c6.8 6.8 15.8 10.3 24.7 10.3 9 0 17.9-3.4 24.7-10.3 13.7-13.7 13.7-35.8 0-49.5l-56.1-56.1 56.1-56.1c13.7-13.7 13.7-35.8 0-49.5-13.7-13.7-35.8-13.7-49.5 0l-56.1 56.1-56.1-56.1c-13.7-13.7-35.8-13.7-49.5 0-13.7 13.7-13.7 35.8 0 49.5l56.1 56.1-56.1 56.1c-13.4 13.7-13.4 35.8 0.2 49.5z'
                }
            }
        ]
    }
};
