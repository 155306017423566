/*
 * @Author: wenxiujiang 
 * @Date: 2021-10-08 15:49:21 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-10-08 17:10:38
 */

/* eslint-disable max-len */

export default {
    name: 'transfer-customer-service',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M924.672256 322.048h-41.984a1.792 1.792 0 0 1-1.536 0 373.76 373.76 0 0 0-740.096 0h-43.52A98.304 98.304 0 0 0 0.000256 420.608v181.504a98.304 98.304 0 0 0 98.304 98.56h139.52a1.536 1.536 0 0 0 1.536-1.536V382.208A278.272 278.272 0 0 1 486.400256 102.4a272.384 272.384 0 0 1 296.96 270.848v274.944A273.408 273.408 0 0 1 509.696256 921.6h-2.048v102.4H512.000256a374.272 374.272 0 0 0 369.152-322.304 1.792 1.792 0 0 1 1.536 0h41.984A98.56 98.56 0 0 0 1024.000256 602.112v-181.504a98.56 98.56 0 0 0-99.328-98.56zM137.472256 597.504v1.28H102.400256a1.536 1.536 0 0 1-1.536-1.28v-172.288a1.536 1.536 0 0 1 1.536-1.28h33.792v1.28z m783.872 0l-1.28 1.28h-33.792v-173.568-1.28h33.792l1.28 1.28z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M512.000256 746.752A368.384 368.384 0 0 0 709.376256 691.2l3.84-2.56-53.76-86.272a286.72 286.72 0 0 1-133.376 42.752 286.464 286.464 0 0 1-162.304-43.008l-54.016 86.272 4.096 2.56a366.848 366.848 0 0 0 196.096 56.832z'
                }
            }
        ]
    }
};
