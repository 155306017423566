/*
 * @Author: wenxiujiang 
 * @Date: 2023-08-03 18:17:42 
 * @Last Modified by: jintingliu
 * @Last Modified time: 2024-07-18 12:56:25
 */

import Satisfaction from 'common/resource/satisfaction';

export default {
    state: {
        satisfactionMap: null,
        isBackOpenSatisfaction: null
    },

    getters: {
        satisfactionMap: state => state.satisfactionMap,
        isBackOpenSatisfaction: state => state.isBackOpenSatisfaction
    },

    mutations: {
        saveSatisfaction(state, params) {
            if (params) {
                state.satisfactionMap = {
                    ...state.satisfactionMap,
                    [params.conversationId]: params
                };
                state.isBackOpenSatisfaction = true;
            } else {
                state.isBackOpenSatisfaction = null;
            }

            return;
        }
    },

    actions: {
        async fetchSatisfaction({commit}, {conversationId, userId}) {
            if (!conversationId || conversationId === -1 || !userId) return;

            const {data: {body}} = await Satisfaction.getEvaluationDetail({params: {conversationId, userId}, hideLoading: true});

            commit('saveSatisfaction', body);
        }
    }
};
