/*
 * @Author: wenxiujiang 
 * @Date: 2022-12-07 15:13:44 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-12 23:40:29
 */

/* eslint-disable max-len */

export default {
    name: 'talk-service',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M840.5504 416.5632h-4.8128c-50.5344 0-91.4944 40.96-91.4944 91.4944v129.6384c0 50.5344 40.96 91.4944 91.4944 91.4944h4.8128c50.5344 0 91.4944-40.96 91.4944-91.4944V508.0576c0-50.5344-40.96-91.4944-91.4944-91.4944zM176.2304 416.5632h-4.8128c-50.5344 0-91.4944 40.96-91.4944 91.4944v129.6384c0 50.5344 40.96 91.4944 91.4944 91.4944h4.8128c50.5344 0 91.4944-40.96 91.4944-91.4944V508.0576c0.0512-50.5344-40.96-91.4944-91.4944-91.4944z',
                    fill: '#FACC31'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M860.0064 392.6016c-11.5712-177.5616-159.6416-318.5152-340.1216-318.5152h-32.8704c-180.7872 0-329.0624 141.4656-340.1728 319.488-52.8384 11.3152-92.5184 58.368-92.5184 114.4832v129.6384c0 64.5632 52.5312 117.0944 117.0944 117.0944h4.8128c64.5632 0 117.0944-52.5312 117.0944-117.0944V508.0576c0-57.0368-41.0112-104.704-95.1296-114.9952 11.264-149.5552 136.448-267.776 288.8192-267.776h32.8704c152.7296 0 278.1184 118.784 288.8704 268.8512-51.6096 12.1856-90.1632 58.624-90.1632 113.9712v129.6384c0 42.1888 22.4256 79.2064 55.9616 99.84-24.1664 29.5936-75.1616 77.7728-158.0032 90.4192-9.5232-38.0416-43.9296-66.304-84.8896-66.304H457.1136c-48.2816 0-87.6032 39.2704-87.6032 87.6032v3.2768c0 48.2816 39.2704 87.6032 87.6032 87.6032h74.5984c38.7584 0 71.6288-25.2928 83.1488-60.2624 120.7296-14.592 187.2896-91.4432 211.2512-125.44 3.1744 0.256 6.3488 0.4096 9.6256 0.4096h4.8128c64.5632 0 117.0944-52.5312 117.0944-117.0944V508.0576c0-57.9072-42.2912-106.1376-97.6384-115.456zM242.176 508.0576v129.6384c0 36.352-29.5424 65.8944-65.8944 65.8944h-4.8128c-36.352 0-65.8944-29.5424-65.8944-65.8944V508.0576c0-36.352 29.5424-65.8944 65.8944-65.8944h4.8128c36.3008 0 65.8944 29.5424 65.8944 65.8944z m325.9392 344.4224c0 20.0704-16.3328 36.4032-36.4032 36.4032H457.1136c-20.0704 0-36.4032-16.3328-36.4032-36.4032v-3.2768c0-20.0704 16.3328-36.4032 36.4032-36.4032h74.5984c20.0704 0 36.4032 16.3328 36.4032 36.4032v3.2768z m338.3296-214.784c0 36.352-29.5424 65.8944-65.8944 65.8944h-4.8128c-4.1984 0-8.2944-0.4096-12.2368-1.1776-2.4576-0.9728-4.9664-1.6384-7.5264-1.8432-26.7264-8.448-46.1312-33.4336-46.1312-62.8736V508.0576c0-36.352 29.5424-65.8944 65.8944-65.8944h4.8128c36.352 0 65.8944 29.5424 65.8944 65.8944v129.6384z',
                    fill: '#191919'
                }
            }
        ]
    }
};
