/*
 * @Author: xiaoxueli
 * @Date: 2023-08-15 17:07:05
 * @Last Modified by: xiaoxueli
 * @Last Modified time: 2023-08-15 17:36:07
 */

/* eslint-disable max-len */

export default {
    name: 'provinces-cities',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M218 769.35z m10.68-595.75h-0.01 0.01zM512 960c-106.57 0-207-7.68-282.71-21.63-37.93-7-68-15.27-89.52-24.61-11.14-4.86-45.05-19.62-45.05-49.76 0-19.67 14.63-36.18 43.49-49.06 20.62-9.21 49.58-17.41 86.07-24.37a23.48 23.48 0 0 1 8.79 46.12c-55.85 10.66-78.63 21.58-87.37 27.31 9.8 6.44 37.2 19.4 108.87 31.11 70.84 11.57 162.26 17.94 257.43 17.94s186.6-6.37 257.43-17.94C841.1 883.4 868.5 870.44 878.3 864c-8.66-5.68-31.09-16.44-85.79-27a23.48 23.48 0 1 1 8.9-46.1c36 7 64.57 15.11 84.9 24.27 28.51 12.85 43 29.27 43 48.83 0 30.14-33.91 44.9-45 49.76-21.48 9.34-51.59 17.63-89.52 24.61C719 952.32 618.57 960 512 960z m371.32-91.9zM512 278a132.61 132.61 0 1 1-93.77 38.84A131.78 131.78 0 0 1 512 278m0-47a179.56 179.56 0 1 0 179.56 179.6A179.56 179.56 0 0 0 512 231z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M512 111a292.88 292.88 0 0 1 207.17 500.11L512 818.28 304.73 611a292.71 292.71 0 0 1 0.08-414.24A292 292 0 0 1 512 111m0-47a338.9 338.9 0 0 0-240.37 99.56 339.93 339.93 0 0 0-0.1 480.65L512 884.68l240.37-240.37A339.94 339.94 0 0 0 512 64z'
                }
            }
        ]
    }
};
