/*
 * @Author: yuhaoyang
 * @Date: 2023-02-06 11:03:57
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2024-06-14 18:50:53
 */
export default {
    zh: {
        vant: {
            save: '保存',
            confirm: '确认',
            cancel: '取消',
            vanCascader: {
                select: '请选择',
            },
        }
    },
    id: {
        vant: {
            save: 'Menyimpan',
            confirm: 'Konfirmasi',
            cancel: 'Batal',
            vanCascader: {
                select: 'tolong pilih',
            }
        }
    },
    en: {
        vant: {
            save: 'save',
            confirm: 'confirm',
            cancel: 'cancel',
            vanCascader: {
                select: 'please select',
            },
        }
    },
    th: {
        vant: {
            save: 'บันทึก',
            confirm: 'ยืนยัน',
            cancel: 'ยกเลิก',
            vanCascader: {
                select: 'ยกเลิก'
            }
        }
    }
};
