/*
 * @Author: wenxiujiang 
 * @Date: 2021-07-20 16:28:12 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-07-20 16:34:28
 */

/* eslint-disable max-len */

export default {
    name: 'onlineSystem',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M143.36 699.733333l366.933333 160.426667L877.226667 699.733333c17.066667-6.826667 35.84 0 42.666666 17.066667 6.826667 17.066667 0 35.84-15.36 42.666667l-378.88 165.546666c-3.413333 1.706667-5.12 1.706667-8.533333 1.706667-6.826667 1.706667-13.653333 0-18.773333-1.706667L117.76 759.466667c-10.24-5.12-18.773333-15.36-18.773333-25.6-1.706667-11.946667 3.413333-22.186667 13.653333-29.013334s20.48-10.24 30.72-5.12z m1.706667-221.866666L512 638.293333 877.226667 477.866667c10.24-5.12 23.893333-3.413333 32.426666 3.413333 10.24 6.826667 15.36 18.773333 13.653334 30.72-1.706667 11.946667-8.533333 22.186667-20.48 25.6l-378.88 165.546667c-10.24 5.12-23.893333 3.413333-32.426667-3.413334L119.466667 537.6c-8.533333-3.413333-13.653333-10.24-17.066667-18.773333-3.413333-8.533333-3.413333-17.066667 0-25.6s10.24-13.653333 18.773333-17.066667c6.826667-1.706667 15.36-1.706667 23.893334 1.706667zM510.293333 97.28h3.413334c5.12 0 10.24 1.706667 15.36 3.413333l366.933333 157.013334c17.066667 6.826667 29.013333 23.893333 27.306667 42.666666 0 18.773333-11.946667 35.84-29.013334 42.666667L529.066667 493.226667c-11.946667 5.12-23.893333 5.12-35.84 0L126.293333 343.04c-17.066667-6.826667-29.013333-23.893333-29.013333-42.666667s10.24-35.84 27.306667-42.666666L493.226667 102.4c5.12-3.413333 11.946667-5.12 17.066666-5.12z m0 66.56l-317.44 136.533333 317.44 129.706667 317.44-129.706667-317.44-136.533333z'
                }
            }
        ]
    }
};
