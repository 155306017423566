/*
 * @Author: zhijiezhang 
 * @Date: 2021-04-28 18:59:29 
 * @Last Modified by: yuhaoyangngqianguan.comngqianguan.com
 * @Last Modified time: 2023-06-12 14:39:42
 */

import Vue from 'vue';

import SvgIcon from '../component/svg-icon';

Vue.component('SvgIcon', SvgIcon);

const req = require.context('./svg', false, /\.svg$/);
req.keys().map(req);
