/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 16:03:17 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:03:47
 */

/* eslint-disable max-len */

export default {
    name: 'date-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M346.639 292.484h10.884c16.355 0 22.674-23.698 22.674-40.053v-130.721c0-16.355-8.163-34.553-24.487-34.553h-10.884c-16.355 0-23.581 18.198-23.581 34.553v136.192c0 10.884 9.070 34.582 25.395 34.582zM696.203 292.484h10.884c16.355 0 23.581-23.698 23.581-40.053v-130.721c0-16.355-6.32-34.553-22.674-34.553h-10.884c-16.355 0-24.487 18.198-24.487 34.553v136.192c0 10.884 12.668 34.582 23.581 34.582zM851.471 146.11h-90.931v57.871h90.931c32.679 0 59.919 32.124 59.919 64.805v54.477h-768.146v-54.477c0-32.679 27.238-64.805 59.919-64.805h89.117v-57.871h-83.675c-65.39 0-121.71 68.198-121.71 128.117v528.443c0 59.919 56.349 132.681 121.71 132.681h648.309c59.919 0 107.081-67.321 107.081-132.681v-528.443c-5.413-59.919-47.133-128.117-112.523-128.117zM908.317 797.257c0 38.122-18.695 79.989-56.847 79.989h-642.838c-32.681 0-63.577-41.867-63.577-74.547v-424.96h763.29v419.518zM643.569 146.11h-234.379v57.871h234.379v-57.871z'
                }
            }
        ]
    }
};
