/*
 * @Author: wenxiujiang 
 * @Date: 2021-06-09 09:47:08 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-06-09 09:49:11
 */

/* eslint-disable max-len */

export default {
    name: 'star-unmark',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M733.653 918.642c-12.368 0-25.232-2.968-36.116-8.905l-184.043-96.474-183.548 96.969c-11.379 5.937-23.747 8.905-36.611 8.905-15.337 0-30.674-4.453-43.042-12.863-23.747-16.326-36.116-44.032-31.169-71.737l35.621-209.274L99.894 473.872c-20.284-19.79-27.211-47.99-17.811-74.211 9.4-26.221 32.158-44.526 60.358-48.484L353.2 320.503l91.527-186.022C457.095 109.25 484.305 92.924 513 92.924c29.19 0 55.905 16.326 68.274 41.558L672.8 320.503l210.758 30.674c28.2 3.958 51.453 22.758 60.358 48.484 9.4 26.221 2.474 54.916-17.811 74.705L771.253 625.262l35.621 209.274c4.947 27.705-7.421 55.411-31.169 71.737-11.873 7.916-26.715 12.369-42.052 12.369zM513.495 743.504c8.905 0 17.316 1.979 25.232 5.937l190.969 100.432c0.989 0.495 2.474 0.99 3.958 0.99 1.979 0 3.463-0.495 4.947-1.484s2.474-2.968 2.474-4.453l-37.105-217.19c-2.968-16.821 2.969-34.137 14.842-46.011l160.295-156.337c0.989-0.989 1.979-2.474 1.484-4.453-0.495-1.484-2.968-3.463-5.937-3.958L655.98 385.314c-17.316-2.474-32.653-13.358-40.074-29.19l-94.99-192.948c-0.989-1.979-3.958-3.463-6.926-3.463s-5.937 1.484-6.926 3.958l-94.99 192.948c-7.916 15.832-22.758 26.221-40.074 29.19l-219.664 31.663c-2.968 0.495-5.442 2.474-5.937 3.958-0.495 1.484 0 2.968 1.484 4.453l160.295 156.832c12.368 11.874 17.811 29.19 14.842 46.011l-37.105 216.695c-0.495 1.484 0.495 3.463 2.474 4.947 1.484 0.989 2.968 1.484 4.947 1.484 1.484 0 2.968-0.495 4.453-0.989l190.474-100.432c7.916-4.453 16.326-6.927 25.232-6.927z'
                }
            }
        ]
    }
};
