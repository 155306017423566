import { render, staticRenderFns } from "./tool-box.vue?vue&type=template&id=fc50f7fa&scoped=true&"
import script from "./tool-box.vue?vue&type=script&lang=js&"
export * from "./tool-box.vue?vue&type=script&lang=js&"
import style0 from "./tool-box.vue?vue&type=style&index=0&id=fc50f7fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc50f7fa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web474js/fintopia-web-phi-ci-prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fc50f7fa')) {
      api.createRecord('fc50f7fa', component.options)
    } else {
      api.reload('fc50f7fa', component.options)
    }
    module.hot.accept("./tool-box.vue?vue&type=template&id=fc50f7fa&scoped=true&", function () {
      api.rerender('fc50f7fa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/tool-box.vue"
export default component.exports