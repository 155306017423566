/*
 * @Author: zhijiezhang 
 * @Date: 2020-10-24 17:58:12 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2021-05-29 15:30:02
 */

export const UNKNOWN_ERROR = '未知错误';

export const YQG_STATUS_CODE_SUCCESS = 0;
export const YQG_STATUS_CODE_DEFAULT_PASSWORD = 20003;
export const YQG_STATUS_CODE_EXPIRED_PASSWORD = 20004;
export const YQG_STATUS_CAPTCHA_NOT_MATCH = 30001;
export const YQG_STATUS_NEED_CAPTCHA = 30002;
export const YQG_STATUS_EMPTY_CAPTCHA = 30003;
export const YQG_STATUS_CAPTCHA_OVERDUE = 30004;
export const YQG_STATUS_CODE_BATCH_CONTENT_ERROR = 44002;
