/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 15:57:34 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:38:01
 */

/* eslint-disable max-len */

export default {
    name: 'radio-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M288.252565 512.00307c0 29.28907 5.820566 58.564837 17.032932 85.621053 11.206226 27.061332 27.79095 51.881624 48.501644 72.589248 20.710694 20.709671 45.526893 37.294394 72.589248 48.500621 27.056216 11.211343 56.337099 17.032932 85.625146 17.032932 29.28907 0 58.564837-5.820566 85.622076-17.032932 27.061332-11.206226 51.881624-27.79095 72.589248-48.500621 20.710694-20.706601 37.294394-45.526893 48.500621-72.589248 11.211343-27.056216 17.032932-56.333006 17.032932-85.621053 0-29.290093-5.820566-58.569954-17.032932-85.626169-11.206226-27.061332-27.789926-51.877531-48.500621-72.589248-20.706601-20.710694-45.526893-37.294394-72.589248-48.501644-27.056216-11.211343-56.333006-17.031908-85.622076-17.031908-29.288047 0-58.56893 5.820566-85.625146 17.031908-27.061332 11.207249-51.877531 27.79095-72.589248 48.501644s-37.295418 45.526893-48.501644 72.589248C294.074154 453.433116 288.252565 482.712977 288.252565 512.00307L288.252565 512.00307zM288.252565 512.00307'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M64.267211 526.511505l0-28.010961c0.255827-1.961678 0.521886-3.924379 0.760317-5.886057 2.851954-23.622 4.168949-47.552016 8.832156-70.814836 14.08581-70.289879 44.00012-133.50462 88.962148-189.422164 48.500621-60.325947 108.586091-105.312534 180.209338-134.705981 42.825364-17.572214 87.366813-28.183899 133.586484-31.54853 7.624654-0.554632 15.243169-1.221828 22.865777-1.837858l25.010627 0c2.299369 0.265036 4.594645 0.680499 6.903224 0.769526 40.862663 1.629103 80.755232 8.746198 119.573329 21.527292 82.525552 27.166733 151.684678 74.252121 206.794833 141.319609 58.520835 71.216995 91.911316 153.009859 100.171444 244.960061 3.841491 42.793642 1.497097 85.243453-6.865361 127.3392-9.78997 49.2681-27.448142 95.583962-53.04103 138.815579-42.439578 71.687716-100.256378 127.664611-173.508729 167.210279-75.269287 40.635489-155.904793 57.845453-241.337557 52.557006-43.026956-2.663666-84.965114-10.900258-125.163651-26.302039-124.028804-47.530526-211.682144-132.782166-262.136256-255.757988-16.796548-40.940434-26.384927-83.742263-29.727045-127.904065C65.592392 541.376051 64.899614 533.945825 64.267211 526.511505L64.267211 526.511505zM512.174474 904.414391c217.760582-0.727571 392.293641-176.021969 392.020418-392.388808-0.274246-217.240743-175.281095-391.664308-391.844409-391.908878-216.523405-0.242524-392.100236 175.205371-392.276245 391.982556C119.903346 728.498846 295.680744 904.378575 512.174474 904.414391L512.174474 904.414391zM512.174474 904.414391'
                }
            }
        ]
    }
};
