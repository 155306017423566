/*
 * @Author: jintingliu
 * @Date: 2021-08-05 16:18:37
 * @Last Modified by: jintingliu
 * @Last Modified time: 2021-08-05 16:51:04
 */

/* eslint-disable max-len */
export default {
    name: 'transfer',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M768 699.818667L896 810.666667l-128 110.848L767.957333 853.333333H512v-85.333333h256v-68.181333zM512 85.333333a213.333333 213.333333 0 0 1 145.066667 369.749334 383.488 383.488 0 0 1 152.277333 112.554666 42.666667 42.666667 0 1 1-66.048 54.058667 298.666667 298.666667 0 0 0-528.896 214.229333 42.666667 42.666667 0 0 1-85.034667 7.082667A384.128 384.128 0 0 1 366.933333 455.04 213.333333 213.333333 0 0 1 512 85.333333z m0 85.333334a128 128 0 1 0 0 256 128 128 0 0 0 0-256z'
                }
            }
        ]
    }
};
