/*
 * @Author: jintingliu
 * @Date: 2021-08-05 16:18:37
 * @Last Modified by: jintingliu
 * @Last Modified time: 2021-08-05 16:45:55
 */

/* eslint-disable max-len */
export default {
    name: 'modular',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M405.333333 458.666667H149.333333c-29.866667 0-53.333333-23.466667-53.333333-53.333334V149.333333c0-29.866667 23.466667-53.333333 53.333333-53.333333h256c29.866667 0 53.333333 23.466667 53.333334 53.333333v256c0 29.866667-23.466667 53.333333-53.333334 53.333334z m-245.333333-64h234.666667v-234.666667h-234.666667v234.666667zM874.666667 458.666667H618.666667c-29.866667 0-53.333333-23.466667-53.333334-53.333334V149.333333c0-29.866667 23.466667-53.333333 53.333334-53.333333h256c29.866667 0 53.333333 23.466667 53.333333 53.333333v256c0 29.866667-23.466667 53.333333-53.333333 53.333334z m-245.333334-64h234.666667v-234.666667h-234.666667v234.666667zM874.666667 928H618.666667c-29.866667 0-53.333333-23.466667-53.333334-53.333333V618.666667c0-29.866667 23.466667-53.333333 53.333334-53.333334h256c29.866667 0 53.333333 23.466667 53.333333 53.333334v256c0 29.866667-23.466667 53.333333-53.333333 53.333333z m-245.333334-64h234.666667v-234.666667h-234.666667v234.666667zM405.333333 928H149.333333c-29.866667 0-53.333333-23.466667-53.333333-53.333333V618.666667c0-29.866667 23.466667-53.333333 53.333333-53.333334h256c29.866667 0 53.333333 23.466667 53.333334 53.333334v256c0 29.866667-23.466667 53.333333-53.333334 53.333333z m-245.333333-64h234.666667v-234.666667h-234.666667v234.666667z'
                }
            }
        ]
    }
};
