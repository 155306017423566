/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 17:23:00 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:24:53
 */

/* eslint-disable max-len */

export default {
    name: 'user-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 625.778c-159.289 0-284.444-125.156-284.444-284.445S352.71 56.89 512 56.89s284.444 125.155 284.444 284.444S671.29 625.778 512 625.778z m0-56.89c125.156 0 227.556-102.4 227.556-227.555S637.156 113.778 512 113.778s-227.556 102.4-227.556 227.555S386.844 568.89 512 568.89z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M56.889 1024c0-250.311 204.8-455.111 455.111-455.111S967.111 773.689 967.111 1024h-56.889c0-221.867-176.355-398.222-398.222-398.222S113.778 802.133 113.778 1024h-56.89z'
                }
            }
        ]
    }
};
