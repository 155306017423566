/**
 * @Author: center
 * @Date: 2024/6/18-14:50
 * @Last Modified by: xinzhong
 * @Last Modified time: 2024-06-18 15:58:33
 */

/* eslint-disable no-underscore-dangle,camelcase,no-tabs */

/* global __YQG_SENSORS_URL__ */

import Sensors from 'sa-sdk-javascript';
import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6';
import pageload from 'sa-sdk-javascript/dist/web/plugin/pageload/index.es6';

import {isWechatMiniProgram, isTouTiaoMiniProgram, minVersion, isZebra} from '@cs/common/utils';

import {isOverseas, isProd} from 'common/constant/host-cond';

// 仅国内非生产环境、生产端外&端内42000版本已上开放神策
export const openSensor = !isOverseas && (!isProd || !isZebra || minVersion('42000'));

export const initSensor = userId => {
    if (openSensor) {
        Sensors.init({
            server_url: __YQG_SENSORS_URL__,
            is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
            use_client_time: true,
            send_type: 'image',
            show_log: !isProd,
            heatmap: {
                // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
                clickmap: 'default',
                // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
                scroll_notice_map: 'not_collect'
            },
            ...(isZebra && minVersion('42000') // 42000 版本开始支持 app_js_bridge
                ? {app_js_bridge: true}
                : {}
            )
        });

        if (isWechatMiniProgram || isTouTiaoMiniProgram) {
            Sensors.use('SiteLinker',
                {
                    linker: [
                        {part_url: 'yangqianguan.com', after_hash: false},
                        {part_url: 'fintopia.tech', after_hash: false}
                    ],
                    // 该配置设置为 true 后，如果从已登录的页面跳转过来，即使当前网页已经登录，当前网页仍然会以之前网页的登录 id 再次登录。
                    re_login: false
                });
        }

        let platform_name = 'zebra_outside';

        if (isZebra) {
            platform_name = 'zebra_app';
        } else if (isWechatMiniProgram) {
            platform_name = 'WeChat_MiniProgram';
        } else if (isTouTiaoMiniProgram) {
            platform_name = 'Douyin_MiniProgram';
        }

        Sensors.registerPage({
            fin_user_id: userId,
            is_login: !!userId,
            platform_name
        });
        if (userId) Sensors.login(userId);
        Sensors.quick('autoTrack'); // 用于采集 $pageview 事件
        Sensors.use(pageleave); // 用于采集 WebPageLeave 事件
        Sensors.use(pageload, {max_duration: 10 * 60}); // 10分钟 用于采集 WebPageLoad 事件
    }
};

export default Sensors;
