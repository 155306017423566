/*
 * @Author: xiaoxueli
 * @Date: 2021-04-25 16:22:56
 * @Last Modified by: xiaoxueli
 * @Last Modified time: 2021-04-26 15:25:42
 */

/* eslint-disable max-len */

export default {
    name: 'translate-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M128 96h341.333a32 32 0 0 1 3.072 63.85l-3.072 0.15H160v704h704V554.667a32 32 0 0 1 63.85-3.072l0.15 3.072V896a32 32 0 0 1-28.928 31.85L896 928H128a32 32 0 0 1-31.85-28.928L96 896V128a32 32 0 0 1 28.928-31.85L128 96z m778.667-10.667a32 32 0 0 1 32 32v224a32 32 0 1 1-64 0V191.66l-276.843 258.41a32 32 0 0 1-42.88 0.704l-2.347-2.282a32 32 0 0 1-0.704-42.88l2.283-2.347 272.043-253.93H682.667a32 32 0 0 1 0-64h224z'
                }
            }
        ]
    }
};
