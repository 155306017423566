/*
 * @Author: xiaodongyu
 * @Date 2020-09-10 20:31:07
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-09-10 20:37:08
 */
/* eslint-disable max-len */

export default {
    name: 'custom-music',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 972.8C258.048 972.8 51.2 765.952 51.2 512S258.048 51.2 512 51.2 972.8 258.048 972.8 512c0 17.408-13.312 30.72-30.72 30.72s-30.72-13.312-30.72-30.72c0-220.16-179.2-399.36-399.36-399.36S112.64 291.84 112.64 512 291.84 911.36 512 911.36c17.408 0 30.72 13.312 30.72 30.72S529.408 972.8 512 972.8z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M512 972.8C258.048 972.8 51.2 765.952 51.2 512S258.048 51.2 512 51.2 972.8 258.048 972.8 512c0 83.968-22.528 164.864-65.536 236.544-9.216 14.336-27.648 19.456-41.984 10.24-14.336-9.216-19.456-27.648-10.24-41.984 36.864-61.44 56.32-133.12 56.32-204.8 0-220.16-179.2-399.36-399.36-399.36S112.64 291.84 112.64 512 291.84 911.36 512 911.36c91.136 0 177.152-29.696 247.808-87.04 6.144-5.12 13.312-11.264 19.456-16.384 5.12-4.096 9.216-9.216 14.336-13.312 12.288-12.288 31.744-12.288 43.008 0 12.288 12.288 12.288 31.744 0 43.008-5.12 5.12-10.24 10.24-16.384 15.36-7.168 6.144-14.336 13.312-22.528 19.456C716.8 937.984 617.472 972.8 512 972.8z'

                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M752.64 352.256c-9.216 0-18.432-4.096-24.576-11.264-41.984-53.248-102.4-89.088-169.984-101.376-16.384-3.072-27.648-18.432-24.576-35.84 3.072-16.384 18.432-27.648 35.84-24.576 81.92 14.336 155.648 57.344 206.848 122.88 10.24 13.312 8.192 32.768-5.12 43.008-5.12 5.12-11.264 7.168-18.432 7.168zM381.952 795.648c-23.552 0-47.104-6.144-68.608-18.432-31.744-18.432-55.296-48.128-64.512-83.968-9.216-35.84-5.12-72.704 13.312-104.448s48.128-55.296 83.968-64.512l73.728-19.456c35.84-9.216 72.704-5.12 104.448 13.312s55.296 48.128 64.512 83.968c9.216 35.84 5.12 72.704-13.312 104.448s-48.128 55.296-83.968 64.512l-73.728 19.456c-11.264 3.072-23.552 5.12-35.84 5.12zm73.728-234.496c-7.168 0-13.312 1.024-19.456 3.072l-73.728 19.456c-19.456 5.12-35.84 18.432-46.08 35.84s-13.312 38.912-7.168 58.368c5.12 19.456 18.432 35.84 35.84 46.08s38.912 13.312 58.368 7.168l73.728-19.456c18.432-5.12 34.816-18.432 45.056-35.84 10.24-17.408 13.312-38.912 7.168-58.368-5.12-19.456-18.432-35.84-35.84-46.08-11.264-7.168-24.576-10.24-37.888-10.24z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M563.2 665.6c-17.408 0-30.72-13.312-30.72-30.72V215.04c0-17.408 13.312-30.72 30.72-30.72s30.72 13.312 30.72 30.72v419.84c0 17.408-13.312 30.72-30.72 30.72z'
                }
            }
        ]
    }
};
