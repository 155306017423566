/*
 * @Author: chengyuzhang 
 * @Date: 2020-12-17 14:39:30 
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-12-21 16:55:07
 */

/* eslint-disable max-len */

export default {
    name: 'fast-reply-icon',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M552.8 959c-6.4 0-12.9-1.9-18.4-5.8-10.6-7.5-15.7-20.6-12.8-33.3l17.5-76.7H256.2c-51.1 0-99.3-20-135.6-56.4-36.4-36.4-56.4-84.5-56.4-135.6V254.1c0-51.1 20-99.3 56.4-135.6s84.5-56.4 135.6-56.4h510c51.1 0 99.3 20 135.6 56.4 36.4 36.4 56.4 84.5 56.4 135.6v397.1c0 51.1-20 99.3-56.4 135.6-36.4 36.4-84.5 56.4-135.6 56.4h-23.8L570.1 953.9c-5.3 3.4-11.3 5.1-17.3 5.1zM256.2 126.1c-70.6 0-128 57.4-128 128v397.1c0 70.6 57.4 128 128 128h323c9.7 0 18.9 4.4 25 12 6.1 7.6 8.3 17.6 6.2 27.1l-9 39.4 114.3-73.4c5.2-3.3 11.2-5.1 17.3-5.1h33.2c70.6 0 128-57.4 128-128V254.1c0-70.6-57.4-128-128-128h-510z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M612.5 426.1c-52.5-43.9-120.9-75-196.8-90l44.5-24.4c16.4-9.1 22.4-29.6 13.5-46.1s-29.6-22.4-46.1-13.5l-125 68.6c-14 7.7-20.4 23.7-16.5 38.5 0.7 2.8 1.7 5.6 3.2 8.1l68.6 125c6.1 11.2 17.9 17.6 29.7 17.6 5.5 0 11.1-1.3 16.2-4.1 16.4-9.1 22.4-29.6 13.4-46.1L384 399.4c141 22.4 277 111.1 268.9 266.7-0.9 18.6 13.4 34.6 32.1 35.7h1.9c18 0 32.9-14 33.8-32.1 2.5-49.3-6.1-95.9-26-138.5-18.5-39.6-46.2-74.9-82.2-105.1z'
                }
            }
        ]
    }
};
