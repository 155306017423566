/*
 * @Author: wenxiujiang 
 * @Date: 2022-06-15 11:56:12 
 * @Last Modified by: suzilin
 * @Last Modified time: 2023-09-06 14:41:28
 */

import antID from 'ant-design-vue/es/locale-provider/id_ID';
import antZH from 'ant-design-vue/es/locale-provider/zh_CN';

export const AntLocaleMap = {
    zh: antZH,
    id: antID
};

export const MomentLocaleMap = {
    zh: 'zh-cn',
    id: 'id',
    pl: 'pl'
};
