/*
 * @Author: suzilin
 * @Date: 2023-08-28 14:58:42
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-10-13 11:53:43
 */
export default {
    send: 'Wysłać',
    welcome: 'Cieszę się, że mogę służyć, proszę opisać swój problem',
    detailText: 'Kliknij, aby zobaczyć historię wiadomości',
    recentMessage: 'Poniżej znajduje się aktualny komunikat',
    robotName: 'Przedmiot pożyczki',
    noHistory: 'Brak historii rozmów',
    change: 'Zmień na inny',
    evaluate: 'Oceń',
    system: 'System',
    sendFail: 'Wysyłanie nie powiodło się, kliknij Wyślij ponownie',
    endQueue: 'Zakończ kolejkę',
    copy: 'Kopiować',
    withdraw: 'Cofnij',
    userWithdrawText: 'Cofnąłeś wiadomość.',
    customerWithdrawText: 'Klient wycofał wiadomość',
    evaluation: 'Ocena zadowolenia',
    personsNum: 'Liczba osób',
    robot: 'Robot',
    customer: 'Obsługa klienta przez człowieka',
    clickCopy: 'Proszę kliknąć przycisk "Kopiuj" i otworzyć link w przeglądarce, aby pobrać załącznik',
    copySuccess: 'Skopiowano pomyślnie',
    webTips: 'Abyśmy mogli lepiej Ci służyć, musisz podać następujące informacje',
    name: 'Nazwa',
    required: 'Wymagane',
    phone: 'zarejestrować numer telefonu',
    submit: 'przesłany',
    phoneRight: 'Proszę wprowadzić poprawny numer telefonu!',
    choose: 'Proszę wybrać rodzaj usługi, o którą chcesz zapytać',
    unknownError: 'Nieznany błąd',
    internetError: 'Błąd sieci',
    online: 'Obsługa klienta online',
    toCustomer: 'Przekierowanie do obsługi przez człowieka',
    news: '【Nowa wiadomość】',
    newText: 'Nowa wiadomość',
    typeError: 'Rodzaj załącznika tymczasowo nieobsługiwany dla przesyłania',
    sizeError: 'Nie więcej niż 50 MB',
    reConnect: 'Ponowne łączenie, proszę spróbować ponownie później',
    empty: 'Pole {type} nie może być puste',
    userName: 'nazwa użytkownika',
    userPhone: 'Numer telefonu',
    evaluateTip: 'Proszę ocenić tę obsługę',
    resolvedTip: 'Czy twój problem został rozwiązany?',
    resolved: 'Pomocne',
    unsolved: 'Żadnej pomocy',
    thankTip: 'Dziękujemy za Twoją ocenę, każda opinia daje nam szansę na poprawę',
    pleaseFill: 'Proszę wprowadzić opinię na temat usługi',
    pleaseChoose: 'Proszę zaznaczyć etykiety',
    optional: 'opcjonalny',
    index: 'stronie główny',
    more: 'Przeglądaj więcej',
    noRecord: 'Brak kolejnych zapisów',
    historyRecord: 'Kliknij, aby zobaczyć historię działań',
    remind: 'Przypomnienie.',
    pleaseLeaveMessage: 'Proszę zostawić swoją wiadomość',
    leaveMessageRecord: 'Zapis usługi',
    back: 'Wróć',
    submitSuccess: 'Przesłano pomyślnie',
    remindSuccess: 'Przypomnienie udane.',
    remindTip: 'Postaramy się jak najszybciej przetworzyć Twoje zapytanie, prosimy o cierpliwość',
    leaveMessageDetail: 'Szczegóły usługi',
    date: 'rok miesiąc dzień',
    dateTime: 'rok miesiąc dzień godzina minuta',
    noLeaveMessageRecord: 'Brak zapisów w tym momencie',
    completed: 'Ukończono',
    processing: 'W trakcie przetwarzania',
    viewDetail: 'Przeglądaj szczegóły',
    description: 'Opis',
    pleaseDescription: 'Proszę opisać problem, z którym się spotykasz',
    confirm: 'Potwierdź'
};
