/*
 * @Author: wenxiujiang 
 * @Date: 2021-06-09 09:33:54 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-06-09 09:34:39
 */

/* eslint-disable max-len */

export default {
    name: 'unlock',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M290.133333 512H216.1664c-40.823467 0-73.9328 32.768-73.9328 73.147733v292.5568c0 40.413867 33.109333 73.147733 73.9328 73.147734h591.6672c40.823467 0 73.9328-32.768 73.9328-73.147734v-292.5568c0-40.413867-33.109333-73.147733-73.9328-73.147733H290.133333z m0-73.147733h517.700267c81.681067 0 147.899733 65.501867 147.899733 146.295466v292.5568C955.733333 958.498133 889.514667 1024 807.8336 1024H216.1664C134.485333 1024 68.266667 958.498133 68.266667 877.704533v-292.5568c0-80.7936 66.218667-146.295467 147.899733-146.295466V292.5568C216.1664 131.003733 348.603733 0 512 0c163.362133 0 295.8336 131.003733 295.8336 292.5568v73.147733H733.866667V292.5568c0-121.173333-99.328-219.409067-221.866667-219.409067S290.133333 171.349333 290.133333 292.522667v146.295466z m221.047467 219.4432h1.6384a73.147733 73.147733 0 1 1 0 146.261333h-1.6384a73.147733 73.147733 0 0 1 0-146.261333z'
                }
            }
        ]
    }
};
