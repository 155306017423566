/*
 * @Author: wenxiujiang 
 * @Date: 2022-06-15 11:53:24 
 * @Last Modified by: suzilin
 * @Last Modified time: 2023-09-06 15:47:45
 */

import moment from 'moment';
import {Locale} from 'vant';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

import {MomentLocaleMap} from '@cs/common/constant/language-map';

import {isOverseas, isIndo, isMex, isPhi, isTh, isEu} from 'common/constant/host-cond';

import i18nMap from '../i18n/language';

Vue.use(VueI18n);

export const getLocalLanguage = () => {
    let locale = 'zh';

    if (isOverseas || isIndo) locale = 'id';

    if (isMex) locale = 'es';

    if (isPhi) locale = 'en';

    if (isTh) locale = 'th';

    if (isEu) locale = 'pl';

    moment.locale(MomentLocaleMap[locale]);

    return locale;
};

const i18n = new VueI18n({
    locale: getLocalLanguage(),
    fallbackLocale: 'zh',
    messages: i18nMap,
    silentTranslationWarn: true
});

// vant组建内部国际化
Locale.use(i18n.locale, i18nMap[i18n.locale]?.vant);

export const $t = i18n.t.bind(i18n);
Vue.prototype.$t = $t;

export default i18n;
