/*
 * @Author: wenxiujiang 
 * @Date: 2022-12-07 14:47:05 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-12 23:40:10
 */

/* eslint-disable max-len */

export default {
    name: 'saas-file',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M661.333333 53.333333a32 32 0 0 1 22.613334 9.386667l192 192A32 32 0 0 1 885.333333 277.333333v661.333334a32 32 0 0 1-32 32H170.666667A32 32 0 0 1 138.666667 938.666667V85.333333A32 32 0 0 1 170.666667 53.333333z m-53.333333 64h-405.333333v789.333334h618.666666v-576H640a32 32 0 0 1-31.850667-28.928L608 298.666667V117.333333zM682.666667 682.666667a32 32 0 0 1 3.072 63.850666L682.666667 746.666667H341.333333a32 32 0 0 1-3.072-63.850667L341.333333 682.666667h341.333334z m0-192a32 32 0 0 1 3.072 63.850666L682.666667 554.666667H341.333333a32 32 0 0 1-3.072-63.850667L341.333333 490.666667h341.333334z m-10.666667-349.418667V266.666667h125.418667L672 141.248z',
                    fill: '#544DC5'
                }
            }
        ]
    }
};
