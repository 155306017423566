<!-- @author zhijiezhang -->
<!-- @email zhijiezhang@yangqianguan.com -->
<!-- @date 2021-4-28 18:54:58 -->
<!-- @desc svg-icon.vue -->

<template>
    <svg
        :class="svgClass"
        aria-hidden=""
    >
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        iconClass: {
            type: String,
            required: true
        },
        className: {
            type: String,
            default: null
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
        svgClass() {
            return `svg-icon ${this.className}`;
        }
    }
};
</script>

<style lang="scss" scoped>
    .svg-icon {
        width: 1em;
        height: 1em;
        vertical-align: -.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>

