/*
 * @Author: wenxiujiang 
 * @Date: 2023-10-23 19:12:38 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-11-23 19:23:50
 */

export function setBodyFontSize({useRem, fontSize = 13, remFontSize}) {
    const oHtml = document.getElementsByTagName('html')[0];
    const width = Math.min(oHtml.clientWidth, 550);

    if (useRem) {
        oHtml.style.fontSize = remFontSize || (100 * (width / 375) + 'px');

        return;
    }

    oHtml.style.fontSize = `${fontSize}px`;
}
