/*
 * @Author: zhijiezhang 
 * @Date: 2021-09-29 15:54:03 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2021-09-29 15:54:54
 */

/* global __VUE_DEBUG__ */

import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules,
    strict: __VUE_DEBUG__
});

if (module.hot) { // 开发模式热更新
    module.hot.accept(['./modules'], () => {
        store.hotUpdate({
            modules: require('./modules').default // eslint-disable-line
        });
    });
}

export default store;

