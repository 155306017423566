/*
 * @Author: suzilin
 * @Date: 2022-08-04 18:06:00
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-28 15:27:45
 */

import EVENTS from '@cs/common/constant/events/client';

import EventUtil from '../../common/util/event';

export default BRAND => ({
    bind(el, binding, vnode) {
        const {value} = binding;
        if (!value || !value?.customerId) return;
        value.userId = value.customerId;
        const {key, uploadValue, ...otherParams} = value;
        const {tag, businessEvent} = EVENTS[key] || {};

        if (value.type === 'input') {
            const focusParams = {
                tag: `${tag}InputFocus`,
                businessEvent: `${businessEvent}InputFocus`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            const blurParams = {
                tag: `${tag}InputBlur`,
                businessEvent: `${businessEvent}InputBlur`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            el.addEventListener('focus', () => {
                EventUtil.uploadEvent(focusParams || {}, vnode.context.$route, BRAND);
            });
            el.addEventListener('blur', event => {
                EventUtil.uploadEvent(
                    (uploadValue ? {
                        ...blurParams,
                        ...otherParams,
                        elementContent: {
                            ...otherParams.elementContent,
                            [typeof uploadValue === 'string' ? uploadValue : 'inputVal']: event?.target?.value
                        }
                    } : blurParams) || {},
                    vnode.context.$route,
                    BRAND
                );
            });
        } else {
            el.addEventListener('click', () => {
                EventUtil.uploadEvent(value ? {...value, tag, businessEvent} : {}, vnode.context.$route, BRAND);
            });
        }
    }
});
