var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tool" },
    [
      _c("a-button", { attrs: { icon: "tool" }, on: { click: _vm.openTools } }),
      _vm._v(" "),
      _c(
        "a-modal",
        {
          attrs: {
            title: "工具箱",
            visible: _vm.visible,
            "mask-closable": true,
            footer: null,
          },
          on: { cancel: _vm.drawerClose },
        },
        [
          _vm.$options.isOnlyYchat
            ? _c(
                "div",
                { staticClass: "loginLine" },
                [
                  _c("p", [_vm._v("获取token: ")]),
                  _vm._v(" "),
                  _c("a-input", {
                    staticStyle: { margin: "0 10px" },
                    model: {
                      value: _vm.phone,
                      callback: function ($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.getToken } },
                    [_vm._v("\n                登录\n            ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(
            "\n        当前渠道:  " + _vm._s(_vm.curChannel) + "\n        "
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _vm._v("\n            渠道选择\n            "),
              _vm._l(_vm.channels, function (i, idx) {
                return _c(
                  "a-button",
                  {
                    key: idx,
                    staticStyle: {
                      "margin-right": "5px",
                      "margin-bottom": "5px",
                    },
                    attrs: {
                      type:
                        i.code === _vm.$route.query.channelCode
                          ? "primary"
                          : "default",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectChannel(i)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " + _vm._s(i.name) + "\n            "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }