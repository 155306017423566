/*
 * @Author: xiaoxueli
 * @Date: 2021-04-30 14:28:56
 * @Last Modified by:   xiaoxueli
 * @Last Modified time: 2021-04-30 14:28:56 
 */

/* eslint-disable max-len */

export default {
    name: 'plus-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 1024C229.236364 1024 0 794.763636 0 512S229.236364 0 512 0s512 229.236364 512 512-229.236364 512-512 512z m-46.545455-558.545455H279.272727a46.545455 46.545455 0 0 0 0 93.09091h186.181818v186.181818a46.545455 46.545455 0 0 0 93.09091 0v-186.181818h186.181818a46.545455 46.545455 0 0 0 0-93.09091h-186.181818V279.272727a46.545455 46.545455 0 0 0-93.09091 0v186.181818z'
                }
            }
        ]
    }
};
