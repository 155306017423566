/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 15:44:36 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 19:49:38
 */

/* eslint-disable max-len */

export default {
    name: 'text-area-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M607.674182 840.378182v29.253818H209.408V840.378182q65.792 0 84.782545-16.290909 23.435636-19.851636 23.435637-91.298909V115.898182a241.896727 241.896727 0 0 0-128.302546 28.090182q-72.587636 45.637818-86.993454 169.704727h-32.349091L73.309091 78.452364h669.323636l2.327273 235.240727h-32.442182q-14.498909-122.88-85.876363-168.541091a237.777455 237.777455 0 0 0-128.232728-29.253818v616.727273q0 71.400727 23.435637 91.298909 18.874182 16.453818 85.829818 16.453818z m149.201454-354.327273h162.490182a34.187636 34.187636 0 0 1 34.187637 34.187636v0.256a34.187636 34.187636 0 0 1-34.187637 34.187637h-162.513454a34.187636 34.187636 0 0 1-34.187637-34.187637v-0.256a34.187636 34.187636 0 0 1 34.187637-34.187636z m0 203.450182h162.676364a34.071273 34.071273 0 0 1 34.071273 34.071273v0.372363a34.071273 34.071273 0 0 1-34.071273 34.071273h-162.816a34.071273 34.071273 0 0 1-34.071273-34.071273v-0.349091a34.071273 34.071273 0 0 1 34.071273-34.094545z m0.325819 185.157818h162.048a34.397091 34.397091 0 0 1 34.280727 34.350546v2.094545a34.397091 34.397091 0 0 1-34.397091 34.397091h-162.048a34.397091 34.397091 0 0 1-34.397091-34.397091v-2.094545a34.397091 34.397091 0 0 1 34.397091-34.350546z'
                }
            }
        ]
    }
};
