/* global __DEV__,  __STAGE__, __DEBUG__, __API_HOST__, __OCS__ */

export const DEV = __DEV__;

export const STAGE = __STAGE__;

export const DEBUG = __DEBUG__;

export const API_HOST = __API_HOST__;

export const OCS = __OCS__;

export const isOverseas = /overseas|indo|mex|india|brazil|phi|sea|eu/i.test(STAGE);
