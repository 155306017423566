/*
 * @Author: xiaoxueli
 * @Date: 2023-08-15 17:04:38
 * @Last Modified by: xiaoxueli
 * @Last Modified time: 2023-08-15 17:09:58
 */

/* eslint-disable max-len */

export default {
    name: 'brand-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M595.382857 373.028571h-168.228571v73.142858h241.371428V304.274286h-73.142857z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M330.605714 529.554286c-56.32 0-103.862857 35.108571-123.611428 84.114285H102.4V514.194286c0-43.885714 35.84-79.725714 79.725714-79.725715h121.417143l168.96-157.988571h283.062857l163.84 129.462857 45.348572-57.051428-183.588572-145.554286H443.977143L275.017143 361.325714H182.125714C98.011429 361.325714 29.257143 430.08 29.257143 514.194286v171.885714h169.691428c10.971429 62.171429 65.828571 109.714286 130.925715 109.714286s119.954286-47.542857 130.925714-109.714286h146.285714v-73.142857H454.217143c-19.748571-49.005714-68.022857-83.382857-123.611429-83.382857z m0 193.097143c-32.914286 0-59.977143-27.062857-59.977143-59.977143s27.062857-59.977143 59.977143-59.977143 59.977143 27.062857 59.977143 59.977143-27.062857 59.977143-59.977143 59.977143z'
                }
            },
            {
                tag: 'path',
                attrs: {
                    d: 'M686.811429 479.085714h307.931428v73.142857H686.811429zM686.811429 612.937143h307.931428v73.142857H686.811429zM686.811429 747.52h307.931428v73.142857H686.811429z'
                }
            }
        ]
    }
};
