/*
 * @Author: xiaodongyu
 * @Date 2021-12-15 16:32:12
 * @Last Modified by: suzilin
 * @Last Modified time: 2023-11-06 11:52:09
 */

import {axios as http} from '@yqg/resource';

export default {
    methods: {
        async checkVersion({content, title} = {
            title: '发现新版本, 是否刷新页面？',
            content: null
        }) {
            const {data: {body: remoteVersionInfo}} = await http.get('/api-web/get-version', {hideLoading: true});
            const {VERSION_INFO: versionInfo} = window;

            if (!window.VERSION_INFO && remoteVersionInfo?.branch) {
                // eslint-disable-next-line
                console.info(
                    '%c 当前版本: ',
                    'background-color: #2196f3; padding: 6px 12px; border-radius: 2px; font-size: 14px; color: #fff; text-transform: uppercase; font-weight: 600;',
                    `${remoteVersionInfo?.branch} - ${remoteVersionInfo?.date}`
                );
            }

            if (!versionInfo || !versionInfo.version) {
                window.VERSION_INFO = remoteVersionInfo;

                return;
            }

            if (versionInfo.version === remoteVersionInfo.version) {
                return;
            }

            this.$confirm({
                title,
                content,
                cancelText: '取消',
                onOk: () => window.location.reload()
            });
        }
    }
};
