/*
 * @Author: zhijiezhang
 * @Date: 2021-05-17 13 yuhaoyang 
 * @Last Modified by: suzilin
 * @Last Modified time: 2023-07-11 15:16:57
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/ocs-admin/',

    file: {
        url: 'file/client ',
        method: 'post',
        formData: true
    },

    customerFile: {
        url: 'file/client/customer',
        method: 'post',
        formData: true
    },

    customerFileInHelp: {
        url: 'file/client/customer/withClientToken',
        method: 'post',
        formData: true
    },

    uploadBySign: {
        url: 'file/customer/uploadBySign',
        method: 'post',
        formData: true
    },

    recordSelfService: {
        url: 'message/selfServiceClick',
        method: 'put'
    },

    withDrawn: {
        url: 'message/client/withDrawn/:businessCode/:uuid/:userId',
        method: 'post'
    },

    getMessage: {
        url: 'message',
        method: 'get'
    },

    getUserMessage: {
        url: 'message/client',
        method: 'get'
    },

    clicks: {
        url: 'message/clicks',
        method: 'post'
    },

    read: {
        url: 'conversation/read-seq',
        method: 'put'
    },

    getChannelList: {
        url: 'channel/list/condition',
        method: 'get'
    },

    getOpenId: {
        url: '/api-web/get-open-id',
        method: 'get'
    },

    getQuestionList: {
        url: 'robot/question/keyword',
        method: 'get'
    },

    train: {
        url: 'robot/train',
        method: 'post'
    },

    transferToArtificial: {
        url: 'message/transferToArtificial/:conversationId/:userId',
        method: 'post'
    },

    transferCustomerService: {
        url: 'message/transferToArtificial/:conversationId/:userId/true',
        method: 'post'
    },

    evaluation: {
        url: 'message/evaluation',
        method: 'post'
    },

    getQueueOrder: {
        url: 'conversation/sendQueueOrder/:userId',
        method: 'post'
    },

    leaveQueue: {
        url: 'conversation/leaveWaitingQueue/:conversationId/:userId',
        method: 'post'
    },

    getApproximate: {
        url: 'message/approximate',
        method: 'get'
    },

    cancelMarketSms: {
        url: 'message/cancelMarketSms/:conversationId',
        method: 'post'
    },

    getChannelSetting: {
        url: '/ocs-admin/channel-setting/key',
        method: 'get'
    },

    getFashButton: {
        url: '/ocs-admin/channel-setting/button',
        method: 'get'
    },

    getRefundState: {
        url: 'conversation/self-service/refund/:userId',
        method: 'get'
    },

    refund: {
        url: 'conversation/self-service/refund/:userId',
        method: 'post'
    },

    submitVerifyInfo: {
        url: 'conversation/submitVerifyInfo',
        method: 'post'
    },

    verifyIdentity: {
        url: 'message/verifyIdentity',
        method: 'post'
    }
};

export default Resource.create(api);
