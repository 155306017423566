/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 17:15:12 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:16:03
 */

/* eslint-disable max-len */

export default {
    name: 'organization-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M938.879316 703.968013l-63.999084 0 0-159.99522c0-17.666595-14.330457-31.992073-32.002032-31.992073L554.895768 511.98072l0-191.992272 63.989125 0c35.203729 0 64.004063-28.800335 64.004063-63.999084L682.888956 63.996096c0-35.198749-28.800335-63.996096-64.004063-63.996096L426.894613 0c-35.200741 0-63.996096 28.797347-63.996096 63.996096l0 191.994264c0 35.198749 28.795355 63.999084 63.996096 63.999084l63.996096 0 0 191.992272L202.903297 511.981716c-17.671575 0-32.002032 14.325478-32.002032 31.992073l0 159.99522-63.994104 0c-35.200741 0-63.999084 28.800335-63.999084 63.999084l0 191.992272c0 35.203729 28.797347 64.004063 63.999084 64.004063l191.992272 0c35.198749 0 63.999084-28.800335 63.999084-64.004063l0-191.992272c0-35.198749-28.800335-63.999084-63.999084-63.999084l-63.999084 0L234.900349 575.97582l575.97582 0 0 127.993188-63.989125 0c-35.198749 0-63.999084 28.800335-63.999084 63.999084l0 191.992272c0 35.203729 28.800335 64.004063 63.999084 64.004063l191.991276 0c35.198749 0 63.999084-28.800335 63.999084-64.004063l0-191.992272C1002.877404 732.768348 974.07707 703.968013 938.879316 703.968013zM426.894613 63.996096l191.989285 0 0 191.994264L426.894613 255.99036 426.894613 63.996096zM298.899433 959.959369l-191.992272 0 0-191.992272 191.992272 0L298.899433 959.959369zM938.879316 959.959369 746.887044 959.959369l0-191.992272 191.991276 0L938.878321 959.959369z'
                }
            }
        ]
    }
};
