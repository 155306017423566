/*
 * @Author: suzilin
 * @Date: 2022-09-08 18:04:46
 * @Last Modified by: yuhaoyang 
 * @Last Modified time: 2023-02-07 18:05:30 
 */

import {create} from '@yqg/resource';

// ychat和client共用
const api = {
    uploadEvents: {
        url: '/event-api/web/uploadEvents',
        method: 'post'
    }
};

export default create(api);
