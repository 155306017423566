/*
 * @Author: wenxiujiang 
 * @Date: 2022-12-07 14:47:05 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-12 23:39:05
 */

/* eslint-disable max-len */

export default {
    name: 'calling',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M428.8 595.2c64 64 137.6 121.6 166.4 92.8 41.6-41.6 67.2-76.8 156.8-3.2s22.4 121.6-19.2 163.2c-44.8 44.8-217.6 3.2-387.2-166.4S131.2 339.2 176 291.2c38.4-41.6 89.6-108.8 163.2-19.2s38.4 115.2-3.2 156.8c-28.8 32 32 102.4 92.8 166.4z m92.8-300.8s-19.2-3.2-32 9.6c-12.8 16-12.8 38.4 0 51.2 9.6 9.6 19.2 9.6 19.2 9.6 22.4 3.2 57.6 12.8 96 51.2 38.4 38.4 44.8 73.6 48 96 0 0 3.2 12.8 9.6 19.2 12.8 12.8 38.4 12.8 51.2 0 12.8-12.8 9.6-32 9.6-32-9.6-51.2-32-99.2-70.4-137.6-32-32-80-57.6-131.2-67.2z m179.2 28.8c70.4 70.4 99.2 147.2 89.6 211.2 0 0-3.2 19.2 9.6 32s38.4 16 51.2 0c9.6-9.6 9.6-22.4 9.6-22.4 9.6-54.4-3.2-166.4-108.8-275.2S534.4 153.6 480 163.2c0 0-16 3.2-22.4 9.6-6.4 6.4-9.6 16-9.6 25.6 0 9.6 3.2 19.2 9.6 25.6 12.8 12.8 32 9.6 32 9.6 64-9.6 140.8 22.4 211.2 89.6z',
                    fill: '#2F9F4E'
                }
            }
        ]
    }
};
