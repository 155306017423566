/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 17:26:16 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-07-30 17:27:20
 */

/* eslint-disable max-len */

export default {
    name: 'date-time-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M583.68 157.696v-28.672c0-12.288 8.192-20.48 20.48-20.48s20.48 8.192 20.48 20.48v28.672h163.84c57.344 0 102.4 45.056 102.4 102.4v73.728c0 12.288-8.192 20.48-20.48 20.48s-20.48-8.192-20.48-20.48v-73.728c0-34.816-26.624-61.44-61.44-61.44h-163.84v36.864c0 12.288-8.192 20.48-20.48 20.48s-20.48-8.192-20.48-20.48v-36.864h-266.24v36.864c0 12.288-8.192 20.48-20.48 20.48s-20.48-8.192-20.48-20.48v-36.864h-143.36c-34.816 0-61.44 26.624-61.44 61.44v118.784h399.36c12.288 0 20.48 8.192 20.48 20.48s-8.192 20.48-20.48 20.48H71.68v393.216c0 34.816 26.624 61.44 61.44 61.44h337.92c12.288 0 20.48 8.192 20.48 20.48s-8.192 20.48-20.48 20.48H133.12c-57.344 0-102.4-45.056-102.4-102.4v-552.96c0-57.344 45.056-102.4 102.4-102.4h143.36v-28.672c0-12.288 8.192-20.48 20.48-20.48s20.48 8.192 20.48 20.48v28.672h266.24z m374.784 626.688c-6.144 10.24-18.432 14.336-28.672 8.192-10.24-6.144-14.336-18.432-8.192-28.672 63.488-114.688 22.528-258.048-92.16-319.488-114.688-63.488-258.048-22.528-319.488 92.16s-22.528 258.048 92.16 319.488c81.92 45.056 182.272 36.864 256-18.432 8.192-6.144 22.528-4.096 28.672 4.096 6.144 8.192 4.096 22.528-4.096 28.672-86.016 65.536-204.8 73.728-301.056 20.48-133.12-73.728-182.272-241.664-108.544-376.832s241.664-182.272 376.832-108.544c135.168 77.824 182.272 245.76 108.544 378.88z m-231.424-319.488v184.32l167.936 36.864c10.24 2.048 18.432 14.336 16.384 24.576s-14.336 18.432-24.576 16.384l-200.704-45.056v-217.088c0-12.288 8.192-20.48 20.48-20.48s20.48 8.192 20.48 20.48z m-518.144 94.208c34.816-8.192 61.44-24.576 79.872-49.152h24.576V768h-43.008v-196.608c-14.336 10.24-34.816 18.432-61.44 22.528v-34.816z'
                }
            }
        ]
    }
};
