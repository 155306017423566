/*
 * @Author: zhijiezhang 
 * @Date: 2020-07-30 17:29:21 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-09-07 17:04:32
 */

/* eslint-disable max-len */

export default {
    name: 'time-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M512 1024A512 512 0 1 1 512.064-0.064 512 512 0 0 1 512 1024zM512 64C264.768 64 64.192 264.576 64 512a448 448 0 1 0 448-448z m-32 192a32 32 0 0 1 32 32v320a32 32 0 0 1-64 0v-320a32 32 0 0 1 32-32z m0 320h256a32 32 0 1 1 0 64h-256a32 32 0 0 1 0-64z'
                }
            }
        ]
    }
};
