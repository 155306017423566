/*
 * @Author: wenxiujiang 
 * @Date: 2022-12-07 14:47:05 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-12-12 23:39:02
 */

/* eslint-disable max-len */

export default {
    name: 'desktop-work',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M815.666626 168.5723 209.015919 168.5723c-44.669362 0-80.886215 36.20662-80.886215 80.886215l0 424.653653c0 44.667316 36.217876 80.887238 80.886215 80.887238l606.650707 0c44.667316 0 80.885192-36.219923 80.885192-80.887238L896.551818 249.459538C896.551818 204.77892 860.333942 168.5723 815.666626 168.5723zM512.359692 734.777853c-22.343891 0-40.453341-18.10945-40.453341-40.442084 0-22.334681 18.10945-40.442084 40.453341-40.442084 22.333658 0 40.443108 18.107403 40.443108 40.442084C552.803823 716.668403 534.694373 734.777853 512.359692 734.777853zM835.88818 633.671107 188.802552 633.671107 188.802552 229.236961l647.084605 0L835.887157 633.671107zM653.912615 795.442514 370.808816 795.442514c-16.755616 0-30.332842 13.58439-30.332842 30.333866 0 16.744359 13.577227 30.331819 30.332842 30.331819l283.102776 0c16.746406 0 30.3134-13.586436 30.3134-30.331819C684.226015 809.027927 670.659021 795.442514 653.912615 795.442514z',
                    fill: '#6CB8E6'
                }
            }
        ]
    }
};
