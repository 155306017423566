/*
 * @Author: zhijiezhang 
 * @Date: 2020-09-07 17:06:09 
 * @Last Modified by: zhijiezhanng
 * @Last Modified time: 2020-09-07 17:15:24
 */

/* eslint-disable max-len */

export default {
    name: 'cascater-control',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M967.8848 291.4304h-181.248v-120.832c0-30.9248-25.1904-56.1152-56.1152-56.1152H56.1152C25.1904 114.4832 0 139.6736 0 170.5984v505.6512c0 30.9248 25.1904 56.1152 56.1152 56.1152h181.248v120.832c0 30.9248 25.1904 56.1152 56.1152 56.1152h674.2016c30.9248 0 56.1152-25.1904 56.1152-56.1152V347.7504a56.07424 56.07424 0 0 0-55.9104-56.32zM84.3776 676.2496c-16.7936 0-28.0576-11.264-28.0576-28.0576V198.656c0-14.1312 11.264-28.0576 28.0576-28.0576H702.464c16.7936 0 28.0576 11.264 28.0576 28.0576V647.9872c0 14.1312-11.264 28.0576-28.0576 28.0576H84.3776z m883.5072 149.0944c0 14.1312-11.264 28.0576-28.0576 28.0576H321.7408c-16.7936 0-28.0576-11.264-28.0576-28.0576v-92.7744h436.8384c30.9248 0 56.1152-25.1904 56.1152-56.1152V347.7504h153.1904c16.7936 0 28.0576 11.264 28.0576 28.0576V825.344z'
                }
            }
        ]
    }
};
